.footer-component {
  .footer-toolbar-component{
    --background: #FFF;
    height: 60px;
    --padding-start: 20px;
    --padding-end: 0;
    --padding-top: 12px;
    --padding-bottom: 12px;
    --border-top: #0d0d0d;
    --border-width: 1px;


    .buttons-container{

      .footer-button{
        --color: #66818f;
        font-size: 14px;
      }
    }
  }
}
