.audio-style-component{
    background: linear-gradient(90deg, #7E82DB 0%, #49C9D8 100%);
    border-radius: 16px;
    height: 100%;
    .image-container {
        position: relative;
        height: 100%;
        background-size: cover;
        border-radius: 16px;

        .image{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 1;
        }

        .duration {
            position: absolute;
            left: var(--extra-small);
            bottom: var(--extra-small);
            white-space: normal;
            color: var(--White);
            background-color: var(--Gray);
            opacity: 0.6;
            max-width: 100%;
            padding: 2px 1rem;
            text-align: center;
            justify-content: center;
            border-radius: 20px;

        }

        .title-container{
            width: 50%;
            height: 100%;
            display: inline-block;
            .title{
                padding: var(--extra-small) 0 0 var(--extra-small);
                white-space: normal;
                width: 100%;
                display: inline-block;
                overflow-wrap: break-word;
                word-wrap: break-word;
                color: var(--White);
            }
        }
        &:hover {
            .add-to-playlist-container{
                display: block;
                width: 24px;
                height: 24px;
                top: var(--unit);
                right: var(--extra-small);

                @media (min-width: 1200px) {
                    width: 30px;
                    height: 30px;
                }
            }
        }
        .add-to-playlist-container{
            position: absolute;
            display: none;
        }
    }
}