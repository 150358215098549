html, body {
  height: 100%;
  width: 100%;
  font-size: 10px;

  .popover{
    .popover-arrow-container{
      background: rgba(0, 0, 0, 0.72);
      color: #fff;
      font-size: 10px;
      line-height: 12px;
      font-weight: 500;
      font-family: "Albert Sans";
      text-align: center;
      height: 41px;
      width: 148px;
      border-radius: 4px;
    }
    .popover-arrow{
      bottom: -9px !important;
    }
  }
}


.journey-gradient-bg {
  background: rgb(139,199,237);
  background: -moz-linear-gradient(180deg, rgba(139,199,237,1) 0%, rgba(74,199,216,1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(139,199,237,1) 0%, rgba(74,199,216,1) 100%);
  background: linear-gradient(180deg, rgba(139,199,237,1) 0%, rgba(74,199,216,1) 100%);
}

ion-alert{
  --background: #fff !important;
}
.app-container {
  font-family: 'Albert Sans', Helvetica Neue, Helvetica, sans-serif;
  --ion-font-family: 'Albert Sans', Helvetica Neue, Helvetica, sans-serif;
  min-height: 100vh;
  height: 100%;

   .download-mobile-app-modal{
    .download-app-exit-button-container{
      background: #fff;
      display: flex;
      justify-content: flex-end;
      > .exit-button-component{
        top: 10px;
        right: 12px;
        position: absolute;
      }
    }
  }

  .assessment-modal-popover{
    &::part(content){
      color: #fff;
      --offset-y: -45px;
    }
    &::part(arrow)::after{
      background: rgba(0, 0, 0, 0.72);
    }
    .assessment-modal-popover-content{
      text-align: center;
      &::part(background){
        background: rgba(0, 0, 0, 0.65);
      }
      &::part(scroll){
        color: #fff;
      }
    }
  }
}

.paper-container {
  padding: 10px 15px;
  background-color: #FFF;
  @media (min-width: 768px) {
    box-shadow: 0 0 4px 3px rgba(0, 0, 0, .1);
  }

  @media (min-width: 740px) {
    padding: 10px 40px;
    border-radius: 16px;
  }

  h1 {
    font-size: 18px;
    text-align: center;
    color: #66818f;
    margin-top: 0.67em;
    margin-bottom: 20px;
  }

  > .title-caption {
    text-align: center;
    font-size: 14px;
    color: #66818f;
    margin-bottom: 40px;
  }

  > .skip-button {
    width: 100%;
    color: #FFF;
    font-weight: bold;

    border-radius: 4px;
    height: 52.5px;
    margin-bottom: 32px;
    --background: #a0a0b3;
    --background-activated: #cbcbe5;
    --background-hover: #cbcbe5;

  }


  .paper-submit-button {
    width: 100%;
    font-weight: bold;

    border-radius: 4px;
    height: 52.5px;
    margin-bottom: 32px;
    --background: #333895;
    --background-activated: #282d91;
    --background-hover: #282d91;
    --background-disabled: #9D9D9D;
  }

  > .field-row {
    display: flex;
    flex-direction: column;

    > .field {
      width: 100%;
      margin-bottom: 18px;
    }

    @media (min-width: 500px) {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      margin-bottom: 32px;

      > .field {
        width: 40%;
        margin: 0;
      }
    }

  }

  > .long-text {
    color: #66818f;
    margin-bottom: 32px;
    width: 100%;
    text-align: left;

    @media (min-width: 500px) {
      margin: 0 10% 40px;
      width: 80%;
      text-align: center;
    }


    .field {
      margin-top: 16px;
      width: 100%;

      @media(min-width: 500px) {
        width: 50%;
      }
    }

    .goals-question-component {
      margin-top: 16px;

      @media(min-width: 500px) {
        margin-left: 25%;
        margin-top: 32px;
      }
    }


    .field-row {
      width: 85%;
      display: flex;
      justify-content: flex-start;
    }

  }


  > .field {
    width: 100%;
    margin: 0 auto 32px;
    display: block;

    @media (min-width: 500px) {
      width: 87%;
    }
  }

  > .please-wait-component {
    padding-top: 8vh;
  }

  > .title-caption {
    text-align: left;
    margin: 32px auto 32px;

    @media (min-width: 500px) {
      text-align: center;
      margin: 32px auto 40px;
    }
  }

  > .question-note {
    color: #66818f;
    font-weight: bold;
    text-align: left;
    margin-bottom: 32px;
    width: 80%;

    @media (min-width: 500px) {
      text-align: center;
      margin: 0 10% 40px;
    }
  }

  .feeling-rating-question-component {
    margin-top: 16px;
    flex-direction: column;
    margin-left: 0;

    @media (min-width: 500px) {
      margin-left: 35%;
    }

    @media (min-width: 680px) {
      flex-direction: row;
      margin-left: 0;
    }
  }
}

.white-button {
  padding: 10px 20px;
  background-color: white;
  border-radius: 10px;
  color: #77CAF1;
  text-decoration: none;

  @media (min-width: 500px) {
    padding: 20px 40px;
  }
}

.text-center {
  text-align: center;
}

.max-width-900 {
  max-width: 900px;
  margin: 0 auto;
}

.swiper-slide {
  cursor: pointer;
}



