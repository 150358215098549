.sign-up-form-component {
    overflow-y: hidden;
    width: 100%;
    margin: 0 auto;
    @media (min-width: 768px){
      width: 700px;
    }
    .sign-up-title{
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: #555555;
    }
    .ion-select{
        margin-bottom: 30px;
        border: #555555 1px solid;
        // color: #000;
        color: #66818f;
        height: 55px;
        border-radius: 4px;
        font-size: 14px;
        // line-height: 17px;
    }
    .ion-input{
        margin-bottom: 30px;
        color: #66818f;
        font-size: 14px;
    }
    .field {
        width: 100%;
    }
    .email-notice {
        width: 100%;
        text-align: center;
        margin-bottom: 30px;
        margin-top: 5px;
        display: inline-block;
        font-size: 12px;
        color: #66818f;
    }
  }
