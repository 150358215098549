.mux-video-player-component {
  background: #0d0d0d;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  display: flex;
  position: relative;
  text-align: center;
  .mux-player-and-recommendations-container{
    position: relative;
    width: 100%;
    height: 100%;
  }
  mux-player {
    height: 100%;
    margin: 0 auto;
  }

  &.no-initial-controls {
    mux-player::part(center play button), mux-player::part(layer vertical-layer), mux-player::part(center seek-backward button), mux-player::part(center seek-forward button) {
      display: none;
    }

    &:hover {
      mux-player::part(center play button), mux-player::part(center seek-backward button), mux-player::part(center seek-forward button) {
        display: inline-block;
      }
      mux-player::part(layer vertical-layer) {
        display: flex;
      }
    }
  }

  .mux-player-error-copy{
    text-align: center;
    display: flex;
    color: #fff;
    width: 100%;
    justify-content: center;
  }
}