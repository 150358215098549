.video-comment-component{
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    width: 100%;
  }

  .comment-container{
    width: 100%;
    .comment-name{
      padding: 2.5rem 0 0 0;
      color: #555555;
      text-transform: uppercase;
    }
    .comment-date{
      padding: var(--unit) 0 0 0;
    }
    .comment-copy{
      padding: 2rem 0 0 0;
    }
    .comment-divider{
      height: 1.5px;
      min-height: 1.5px;
      opacity: 0.1;
    }
    .report-button{
      &::part(native){
        --padding-start: 0;
        --padding-end: 0;
        --padding-top: 0;
        --padding-bottom: 0;
      }
      opacity: .5;
      margin: var(--unit) 0;
    }
  }
  .comment-input-field-container{
    width: 100%;
    height: 141px;


    .comment-input-field{
      height: 141px;
      color: var(--Gray);
      border: #7E82DB 1.5px solid;
      border-radius: 16px;
      background-color: #F4F5F8;
      --padding-start: 15px;
      --padding-end: 15px;
      --padding-top: 15px;
      --padding-bottom: 15px;

    }
    .faq-button{
      --padding-start: 0;
      --padding-end: 0;
      font-size: 14px;
      color: #868686;
      display: block;
    }
  }

  .post-comment-button-container{
    align-self: flex-start;
    padding-bottom: var(--large);
    @media (max-width: 768px) {
      padding-bottom: 64px;
    }
    .post-comment-button{
      display: block;
      margin: var(--unit) 0 0 0;
      --border-radius: 40px;
      width: 200px;
    }
    .post-comment-button:hover{
      color: var(--Green);
      &::part(native) {
        border: solid 2px var(--Green);
      }
    }
  }
  .load-more-button{
    display: block;
    margin: 15px 0 0 0;
    --border-radius: 3px;
    --background: #54b3ae;
    --background-activated: #54B3AEC1;
    --background-hover: #62d1cc;
  }
}