.side-menu-button-component{
  --min-height: calc(64px + env(safe-area-inset-top));
  height: calc(64px + env(safe-area-inset-top));

  .side-menu-button-toolbar{
    --min-height: calc(64px + env(safe-area-inset-top));
    height: calc(64px + env(safe-area-inset-top));
    display: flex;
    align-items: center;
    color: white;
    --color: white;
    --border-width: 0 !important;
    --background: var(--White);
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);

    --padding-start: 0;
    --padding-end: 0;
    --padding-top: 0;
    --padding-bottom: 0;

    .side-menu-buttons{
      --color: var(--Gray);
      .side-menu-button{
        --color: var(--Gray);
      }
      .back-button{
        margin: 0 0 0 var(--extra-small);
        width: 20px;
        height: 20px;

        &::part(native){
          --padding-start: 0;
          --padding-end: 0;
          --padding-top: 0;
          --padding-bottom: 0;
        }
      }
    }

    .notification {
      position: absolute;
      top: 0;
      right: 0;
      width: 1.7rem;
      height: 1.7rem;
      background-color: var(--Green);
      color: white;
      border: none;
      border-radius: 50%;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
  }

    .caption-variant{
      // 100% - back button container - spacing
      width: calc(100% - 38px);
      display: inline-block;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      color: var(--Gray);
      margin: 0.25rem 0 0 0;
      text-align: center;
    }
    .toolbar-logo-container{
      width: 100px;
      height: 30px;
      // 50vw - toolbar container padding - hamburger icon container - half of image width
      margin: 0 calc(50vw - 41px - 50px);
    }
  }
}