.register-page-component{
    .register-page-content{

        .header {
            // width: 100%;
            margin: 0 auto;
            padding-bottom: 20px;
            padding-top: 40px;
            height: auto;
            
        }

        .logo-image {
            display: block;
            margin-left: auto;
            margin-right: auto;
            // width: 50%;
            width: 200px;
            height: auto;
        }

      .body{
        @media (min-width: 500px) {
          padding: 30px 0;
        }
      }
    }
  }