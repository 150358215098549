.resources-home-tile-component{
  margin: 0;
  padding: 0 var(--small);
  display: inline-flex;
  cursor: pointer;
  align-items: center;

  @media (min-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .resources-home-tile-image-container{
    height: 50px;
    width: 50px;
    .resources-home-tile-image{
      max-height: 5rem;
    }
  }
  .resources-home-tile-text-container{
    display: flex;
    align-items: center;
    padding-left: 6px;
    justify-content: center;
    .resources-home-tile-top-text{
      text-decoration: none;
      color: var(--Dark-Blue);
    }
    .resources-home-tile-bottom-text{
      font-weight: bold;
      //padding-top: 1px;
      align-self: flex-start;
      text-decoration: none;
      color: var(--Dark-Blue);
    }
  }
  &.divider{
    border-right: 1.5px solid #F7F2ED;
  }
}

.resources-home-tile-component:first-of-type{
  padding: 0 var(--small) 0 0;
}