.categories-chip-component{
  .categories-chip-label{
    --background: #EFEFEF;
    --color: #000;
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    @media (min-width: 678px) {
      font-size: 16px;
      line-height: 19px;
    }
  }
  &.selected{
    --background: #6F6F6F;

  }
  .categories-chip-icon{
    width: 10px;
    height: 10px;
    background: #6F6F6F;

  }
}