.side-menu-component {
  .side-menu-header{
    --background: var(--White);
    .side-menu-toolbar{
      --background: var(--White);
      --border-width: 0;
      //Toolbar height + figma/design55 height
      height: calc(64px + 73px);
      display: flex;
      .side-menu-title{
        align-items: flex-end;
        text-align: initial;
        --padding-inline: 0;
        padding: 0;
        margin: 0 var(--extra-small);
        width: calc(100% - var(--extra-small) * 2);
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        border-right: none;
        border-left: none;
        border-top: none;
        >h6{
          line-height: 28px;
          padding-bottom: 5px;
        }
      }
    }
  }

  ion-item::part(native){
    --border-width: 0;
    --border-style: none;
  }
  ion-item::part(detail-icon){
    color: #000;
    // --detail-icon-opacity: 0;
  }
  .side-menu-item{
    width: calc(100% - var(--extra-small) * 2);
    height: 55px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-right: none;
    border-left: none;
    border-top: none;
    margin: 0 var(--extra-small);
    --padding-start: 0;
    --inner-padding-end: 0;
    &::part(native){
      --min-height: 55px;
    }
    .side-menu-icon{
      margin-right: var(--extra-small);
      height: 28px;
      width: 28px;
    }
    .side-menu-forward-icon{
      width: 13px;
      height: 13px;
      margin: 0;
    }
    &.top {

    }
    .side-menu-text{

    }
  }
  .side-menu-item:last-child{
    border: none;
    margin-bottom: 24px;
  }
}
