
ion-modal.account-deletion-modal-component::part(content){
  height: 100%;
}

.account-deletion-modal-component{
  --width: 100%;
  --height: 100%;
  --background: #ffffff;

  .ion-page{
    justify-content: start;
  }

  .exit-button-container {
    position: absolute;
    top: 16px;
    right: 16px;

    .exit-button {
      color: #000000;
      --padding-start: 0;
      --padding-end: 0;
      height: 25px;
      width: 25px;
    }
  }
  .warning-text-question{
    margin: 80px auto 0 auto;
    font-size: 23px;
    line-height: 30px;
    color: #000000;
    width: 90%;
    text-align: center;
  }
  .warning-text{
    width: 85%;
    margin: 16px auto 0 auto;
    font-size: 18px;
    line-height: 27px;
    color: #DE0000;
    text-align: center;
    @media (min-width: 768px) {
      text-align: start;
    }
  }

  .buttons-container{
    display: inline-block;
    width: 100%;
    .buttons{
      text-transform: capitalize;
      display: flex;
      width: 266px;
      height: 48px;
      font-style: normal;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      border-radius: 6px;
      @media (min-width: 375px) {
        width: 284px;
      }
      @media (min-width: 414px) {
        width: 302px;
      }

      &.delete-confirmation-button{
        --background: #ff0000;
        --background-activated: #fc2424;
        color: #fff;
        margin: 32px auto 0 auto;

      }
      &.do-not-delete-button{
        margin: 24px auto 0 auto;
        --background: #54b3ae;
        --background-activated: #54B3AEC1;
        --background-hover: #62d1cc;
        color: #4D433C;
      }
    }
  }
}