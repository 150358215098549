.content-category-page{
  .category-content{
    .category-container{
      padding: 20px 24px;
      @media (min-width: 768px) {
        padding: 27px 41px;
      }
      .module-title{
        text-align: center;
        padding: 0 0 4px 0;
        position:relative;
        color: var(--Dark-Blue);

        .back {
          position: absolute;
          top: 0.4rem;
          left: 0;
        }

        @media (min-width: 768px) {
          padding: 0 0 5px 0;
          font-size: 24px;
          line-height: 29px;
        }

      }
      .articles-found-text{
        text-align: center;
        color: var(--Dark-Blue);

      }
      .categories-chip-container{
        padding: 22px 0 29px 0;
        width: 100%;
      }
      .articles-preview-wrapper{
        display: flex;
        justify-content: center;
        width: 100%;
        .articles-preview-container{
          align-self: center;
          display: grid;
          gap: 0;
          row-gap: 16px;
          width: 100%;
          @media (min-width: 678px) {
            gap: 20px 16px;
            //Gap space between columns = 16*1
            grid-template-columns: calc(50% - 8px) calc(50% - 8px);
          }
          @media (min-width: 1080px) {
            //Remove from the percentage the gap space
            //Gap space between columns = 16*2
            grid-template-columns: calc(33.33% - 11px) calc(33.33% - 11px) calc(33.33% - 11px);
          }
          @media (min-width: 1440px) {
            //Gap space between columns = 16*3
            grid-template-columns: calc(25% - 12px) calc(25% - 12px) calc(25% - 12px) calc(25% - 12px);
          }

        }
      }
    }
  }
}