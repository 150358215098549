ion-modal.login-component-modal::part(content){
  width: 500px;
  height: 500px;
  max-height: 500px;
}

.login-component{
  box-shadow: none;
  --background: #fff;
  display: flex;
  --keyboard-offset: 10px !important;

  .login-page-content{
    position: relative;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 5rem;
    &.mobile{
      padding: 2rem;
    }

    .logo-container{
      display: flex;
      justify-content: center;
      margin-bottom: 2rem;
      .logo-image{
        width: 200px;
        height: auto;
      }
    }

    .error-text {
      text-align: center;
    }

    .email-input, .password-input {
      display: block;
      margin: 0 0 var(--small) 0;
      border: solid 1px #92949C;
      border-radius: 10px;
      --inner-border-width: 0;
    }

    .email-input-error {
      display: block;
      margin-bottom: 1.5rem;
      border: solid 1px red;
      border-radius: 10px;
      --inner-border-width: 0;
    }

    .login-button {
      width: 260px;
      margin: var(--small) auto;
      --border-radius: 40px;
      border-radius: 40px;
      &:hover{
        color: var(--Green);
        border: 1.5px solid var(--Green);
        --border-color:  1.5px solid var(--Green);
      }
    }


    .code-input {
      width: 100%;
      display: flex !important;
      flex-wrap: wrap;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance:textfield;
        font-size: 24px;
        @media (max-width: 330px){
          font-size: 16px;
        }
    }

    .code-sent {
      margin-bottom: 10px;
      color: var(--Dark-Blue);
      text-align: center;
    }

    .sent-icon {
        color: #4EDED6;
        margin-top: 2rem;
        font-size: 4rem;
        text-align: center;
    }

    .submit-again {
      color: #333895;
      text-decoration: underline;
      cursor: pointer;
    }

    .code-sent-subtitle {
        margin: 1rem 0 2rem 0;
        text-align: center;
    }
  }

  .loading-spinner {
    width: 100%;
    position:absolute;
    margin-left:auto;
    top:200px;
    @media (max-width: 500px){
      top:270px;
    }
    display: flex;
    justify-content: center;

    ion-spinner{
      margin: auto;
      color: blue;
    }
  }
}
