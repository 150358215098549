.blue{
    background: linear-gradient(90.04deg, #FFFFFF 8.07%, #77CAF1 99.96%);
    border-radius: 16px;
}

.purple{
    background: linear-gradient(90deg, #FFFFFF 0%, #D8BBFF 100%);
    border-radius: 16px;
}

.yellow{
    background: linear-gradient(90deg, #FFFFFF 0%, #FFCD70 100%);
    border-radius: 16px;
}

.green{
    background: linear-gradient(90.04deg, #FFFFFF 15.73%, #94EBE5 99.96%);
    border-radius: 16px;
}

.pink{
    background: linear-gradient(90deg, #FFFFFF 0%, #F5C0C7 100%);
    border-radius: 16px;
}

.video-style-component{
    border-radius: 16px;
    height: 100%;
    .image-container {
        position: relative;
        height: 100%;
        background-size: cover;
        border-radius: 16px;
        aspect-ratio: var(--aspect-ratio);

        .image{
            position: absolute;
            right: 0;
            bottom: 0;
            z-index: 1;
            border-bottom-right-radius: 16px;
            max-height: 100%;
        }

        .duration {
            position: absolute;
            left: var(--extra-small);
            bottom: var(--extra-small);
            white-space: normal;
            color: var(--White);
            background-color: var(--Gray);
            opacity: 0.6;
            max-width: 100%;
            padding: 2px 1rem;
            text-align: center;
            justify-content: center;
            border-radius: 20px;
            z-index: 2;
        }

        .play-icon {
            width: 4rem;
            height: 4rem;
            position: absolute;
            top: calc(50% - 2rem);
            left: calc(50% - 2rem);
            stroke: white;
            display: none;
            z-index: 2;
        }

        &:hover {
            .play-icon {
                display: block;
            }
            .add-to-playlist-container{
                display: block;
                width: 24px;
                height: 24px;
                top: var(--unit);
                right: var(--extra-small);

                @media (min-width: 1200px) {
                    width: 30px;
                    height: 30px;
                }
            }
        }
        .add-to-playlist-container{
            position: absolute;
            display: none;
            z-index: 2;
        }
        .title-container{
            width: 50%;
            display: inline-block;
            z-index: 2;
            position: inherit;
            float: left;
            .title{
                padding: var(--extra-small) 0 0 var(--extra-small);
                white-space: normal;
                width: 100%;
                display: inline-block;
                overflow-wrap: break-word;
                word-wrap: break-word;
                color: var(--Dark-Blue);
                text-align: left;
            }
            .author-container{
                padding: 1rem 0 0 var(--extra-small);
                .author-name{
                    text-transform: uppercase;
                }
            }
        }
    }
}

