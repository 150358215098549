.profile-menu-list{
    .profile-menu-item:first-of-type{
        padding-top: var(--large);
        cursor: text;
        @media (min-width: 768px) {
            padding-top: var(--extra-small);
        }
    }
    .profile-menu-item{
        --min-height: 0;
        padding-top: var(--extra-small);
        padding-bottom: var(--extra-small);
        //width: calc(100% - var(--extra-small) * 2);
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        border-right: none;
        border-left: none;
        border-top: none;
        cursor: pointer;
        margin: 0 var(--extra-small);
        --padding-start: 0;
        --inner-padding-end: 0;
        text-align: start;
        @media (min-width: 768px) {
            width: auto;
        }


        .body-small-variant{
            color: var(--Dark-Blue);
        }
        .subtitle2-variant{
            color: var(--Dark-Blue);
        }
        .text-container{
            padding-left: 7px;
        }

        .profile-menu-icon{
            width: 13px;
            height: 13px;
            cursor: pointer;
            margin: 0;
        }
    }

    .progress-bar-container {
        width: var(--small);
        height: var(--small);
        margin-right: 8px;
    }

    .box-number {
        width: 2rem;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid var(--Green);
        border-radius: 50%;
        padding: 2px;

        &.complete {
            background-color: var(--Green);
            color: var(--White);
            border: none;
        }
    }
}