.more-videos-by-teacher-component{
    display: flex;
    flex-direction: row;
    @media (max-width: 900px) {
        flex-direction: column;
    }
    justify-content: space-between;
    .teacher-container{
      display: flex;
      flex-direction: row;
      .circle {
        height: 90px;
        width: 90px;
        min-width: 90px;
        background-color: #B7ECF2;
        border-radius: 50%;
        display: inline-block;
        overflow: hidden;
        cursor: pointer;
      }
      img {
        border-radius: 50%;
      }
      .column {
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        justify-content: center;
      }
      h3 {
        font-family: 'Albert Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 26px;
        color: #000000;
        margin-bottom: 6px;
        cursor: pointer;
      }
      h4 {
        font-family: 'Albert Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
        margin-top: 0;
      }
    }
    .button-container{
        margin-top: auto;
        margin-bottom: auto;
  
      .more-videos-button{
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;

        @media (max-width: 900px) {
            margin-top: 20px;
        }
      }
      .more-videos-button:hover{
          color: var(--Green);
          &::part(native) {
              border: solid 2px var(--Green);
          }
      }
    }
  }