.teacher-page {

    .teacher-content-container{
      padding: 0 1.5rem 1rem;
  
      .teacher-header {
        padding: 1.5rem;
        @media (min-width: 768px) {
          padding-top: 0;
          display: flex;
          align-items: flex-start;
  
          img {
            order: 2;
            max-width: 40vw;
            max-height: 30vw;
            padding-top: 2rem;
            padding-left: 2rem;
            padding-bottom: 2rem;
          }
  
        }
  
        & > div {
          flex: 1;
        }
  
        h1 {
          font-weight: 400;
          margin-top: 2rem;
          margin-bottom: 2rem;
        }
  
        p {
          color: #555;
          font-size: 1.6rem;
          line-height: 1.4;
  
          @media (min-width: 768px) {
            font-size: 1.2rem;
            max-width: 40vw;
          }
        }
      }
  
      .teacher-content {
        @media (min-width: 768px) {
          display: grid;
          grid-template-columns: repeat(3, 33.3%);
          grid-template-rows: repeat(auto-fill, 1fr);
        }
        @media (min-width: 1024px) {
          grid-template-columns: repeat(4, 25%);
          grid-template-rows: repeat(auto-fill, 1fr);
        }
  
        .content-tile {
          margin: 0 1rem 3rem 1rem;
          --aspect-ratio: 640/360;
  
        }
      }
      .clamp-description {
        margin-bottom: 2rem;
        button {
          background-color: white;
          border: none;
          color: #333;
          padding: 0.5rem;
          text-decoration: underline;
        }
      }
    }
  }