.content-module-page{

  .resources-tiles-wrapper{
    padding: 10px 24px 20px 24px;
    @media (min-width: 678px) {
      padding: 27px 41px;
    }

    .resources-tiles-container{
      align-self: center;
      display: grid;
      gap: 1.5rem;
      grid-template-columns: 1fr;
      @media (min-width: 540px) {
        grid-template-columns: 1fr 1fr
      }
      @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr 1fr;
      }
      @media (min-width: 1300px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }


    }

    .module-title-container{
      position: relative;
      padding: 0 0 2rem 0;

      .module-title {
        text-align: center;
        color: var(--Dark-Blue);
      }

      .back {
        position: absolute;
        top: 0.5rem;
        left: 0;
      }
      @media (min-width: 768px) {
        // padding: 0 0 5px 0;
        // font-size: 24px;
        // line-height: 29px;
      }
    }
  }
}