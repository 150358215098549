.resource-email-form-modal-component{
  --background: #ffffff;

  --border-radius: 6px;
  @media (min-width: 768px) {
    --height: 640px;
  }
  .resource-email-form-modal-wrapper{
    --background: #ffffff;
  }

  .top-container{
    display: flex;
    margin: 0;
    .top-container-copy{
      text-align: center;
      margin: var(--medium) var(--small) 0;
      max-width: none;
    }
    .exit-button-container{
      width: 25px;
      height: 25px;
      position: absolute;
      right: 15px;
      top: 15px;

    }
  }
  .bottom-container{
    margin: auto;
    @media (min-width: 768px) {
      width: 390px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }

    .radio-button-wrapper{
      width: 280px;
      >.radio-button-assessment-question-component{

        padding: var(--small) 0 0 0;
      }
    }

    .name-input-container{
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      width: 100%;

      .text-input-assessment-question-component:first-of-type{
        padding-right: 10px;
      }
      .text-input-assessment-question-component:nth-of-type(2){
        padding-left: 10px;
      }
    }
    .text-input-assessment-question-component{
      width: 100%;
      padding: 20px 0 0 0;
      .assessment-question-copy{
        display: none;
      }
      .assessment-input-container{
        width: 100%;
        .input-item{
          width: 100%;
          .input-label{
            align-self: flex-start;
            margin: 0;

          }
          .text-input{

          }
        }
      }
    }
    .phone-number-input-container{
      width: 100%;
      padding: 2rem 0 0 0;
      .phone-number-input-label{
        display: flex;
        align-items: center;
        align-self: center;
        justify-content: center;
        margin: 0 auto;
      }
      .assessment-question-component{
        .assessment-question-copy{
          display: none;
        }
        .assessment-input-container{

          .input-item{
            width: 170px;
            text-align: center;
            .number-input{
              --padding-top: 8px;
              --padding-bottom: 8px;
              --padding-start: 0;
            }
          }
        }
      }
    }


    .assessment-question-component{
      width: 100%;

      .assessment-question-copy {
        margin: 0 auto;
        padding: 2rem 0 0 0;
        align-self: center;
        align-items: center;

        @media (min-width: 768px) {
          display: flex;
        }
      }
      .assessment-items-container{
        .assessment-checkbox-item{
          margin: 6px 0;
          width: 330px;
          @media (min-width: 768px) {
            width: 360px;
          }
        }

      }
      .assessment-label{
        font-size: 1.5rem;
      }
    }

    .question-input-field{
      margin-top: 2rem;
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1.2;
      height: 8rem;
      color: #555555;
      border: #696969 1px solid;
      border-radius: 4px;
      background-color: #F4F5F8;
      --padding-start: 8px;
    }
  }
  .submit-button-container{
    display: flex;
    width: 100%;
    max-width: 280px;
    margin: var(--large) auto 100px auto;
    align-items: center;
    justify-content: center;

    .submit-button{
      --border-radius: 40px;
      width: 280px;
    }

    .submit-button:hover {
      color: var(--Green);
      &::part(native) {
        border: solid 2px var(--Green);
      }
    }
  }
}