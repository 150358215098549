.schedule-appointment-modal-component{
  --background: #ffffff;
  --border-radius: 16px;
  --width: 100%;
  --height: 100%;
  @media (min-width: 768px) {
    --width: 700px;
    --height: 80%;
  }
  @media (min-width: 1024px) {
    --width: 910px;
    --height: 80%;
  }
  .schedule-appointment-modal-wrapper{
    --background: #ffffff;
    .schedule-appointment-container{
      width: 100%;
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      .top-container{
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        flex-direction: column;
        padding: 2rem 2rem 0;
        @media (min-width: 768px) {
          padding: var(--medium) var(--medium) 0;
        }

        .exit-button-container{
          position: absolute;
          right: 0;
          top: 0;
          padding: var(--extra-small);
        }
        .schedule-appointment-header{

        }
        .schedule-appointment-body{
          margin-top: var(--unit);
        }
      }
      .bottom-container{
        height: 100%;
        width: 100%;
        .accordion-group{
          height: 100%;
          width: 100%;
          .accordion{
            background-color: var(--White);
            border-color: #7E82DB;
            border-width: 1px 0 0 0;
            border-style: solid;
            height: 100%;

            &:first-of-type{
              margin-top: var(--medium);
            }
            &::part(header){
              height: 80px;
              cursor: pointer;
              display: flex;
              align-items: center;
            }
            .accordion-title{
              color: var(--Gray);
              cursor: pointer;
              padding-left: 2rem;
              @media (min-width: 768px) {
                padding-left: var(--medium);
              }
            }

            .schedule-appointment-line-divider-container{
              height: 2px;
              background: white;
              .schedule-appointment-line-divider{
                height: 1px;
                min-height: 1px;
                background: linear-gradient(90deg, #7E82DB 0%, #49C9D8 100%);
                filter: drop-shadow(0 0 0 var(--White));
              }
            }

            .segment-container {
              width: calc(100% - 4rem);
              height: 55px;
              margin: 2.5rem 0 0 2rem;
              @media (min-width: 415px) {
                width: 335px;
              }
              @media (min-width: 768px) {
                margin-left: var(--medium);
              }

              .segment {
                --background: var(--White);
                border: 3px solid var(--Green);
                border-radius: 40px;
                height: 100%;
                width: 100%;

                .segment-button {
                  color: var(--Green);
                  margin: 0;
                  --indicator-color: var(--Green);

                  &:hover {
                    --color-hover: var(--Green);

                  }

                  &::part(native) {
                    padding: 0;
                  }

                  &::part(indicator) {
                    padding: 0;
                  }

                  &.selected {
                    color: var(--White);
                    --background-checked: var(--Green);
                  }
                }
              }
            }

            .clinician-calendar-wrapper{
              padding: 2rem;
              @media (min-width: 768px) {
                padding: var(--medium);

              }
            }
          }

          .step-2-container{
            padding: 0 2rem var(--large) 2rem;
            @media (min-width: 768px) {
              padding: 0 var(--medium) var(--large);
            }
            .clinician-biography-step-2{
              display: flex;
              align-items: center;
              .clinician-image-container{
                display: contents;
                &.hide{
                  display: none;
                }
                .clinician-image{
                  border-radius: 50%;
                  width: 105px;
                  height: 105px;
                }
              }
              .clinician-biography-step-2-text-container{
                height: 100%;
                padding-left: 0;
                @media (min-width: 768px) {
                  padding-left: var(--extra-small);
                }
                .clinician-name{
                  color: var(--Dark-Blue);
                }
                .clinician-meeting-duration{
                  color: var(--Dark-Blue);
                }
                .clinician-time{
                  color: var(--Dark-Blue);
                }
              }
            }
            .terms-container{
              padding-top: var(--small);
              .title{

              }
              .text-area-item{
                padding-top: var(--extra-small);
                height: 290px;
                .textarea-wrapper{
                  .native-textarea{

                  }
                }
                &::part(native){
                  height: 100%;
                  --padding-start: var(--extra-small);
                  --padding-top: var(--extra-small);
                  --padding-bottom: var(--extra-small);
                  --padding-end: var(--extra-small);
                  --background: linear-gradient(90deg, rgba(126, 130, 219, 0.1) 0%, rgba(73, 201, 216, 0.1) 100%);
                  border-radius: 16px;
                  --inner-border-width: 0px;
                }
                .terms-text-area{
                  height: 100%;
                }
              }
              .checkbox-item{
                --padding-start: 0;
                padding-top: var(--extra-small);
                --inner-border-width: 0px;
                --background-hover: none;
                .checkbox{
                  margin-right: 7px;

                  &::part(container){
                    border-radius: 5px;
                    --background: linear-gradient(90deg, rgba(126, 130, 219, 0.1) 0%, rgba(73, 201, 216, 0.1) 100%);
                    display: block;
                  }
                }
                .label{

                }
              }
              .step-2-button-container{
                .step-2-button{
                  width: 280px;
                  height: var(--large);
                  color: var(--White);
                  border-radius: 40px;
                  &::part(native){
                    --border-radius: 40px;
                  }
                  &:hover{
                    --border-radius: 40px;
                    color: var(--Green);
                    border: 1.5px solid var(--Green);
                    --border-color:  1.5px solid var(--Green);
                  }
                }
              }
            }
          }
          .step-3-container{
            padding: 0 2rem var(--large) 2rem;
            @media (min-width: 768px) {
              padding: 0 var(--medium) var(--large);
            }
            .appointment-confirmation-text{

            }
            .clinician-biography-step-3-text-container{
              height: 100%;
              padding-top: var(--large);
              padding-bottom: var(--medium);
              .clinician-name{
                color: var(--Dark-Blue);
              }
              .clinician-meeting-duration{
                color: var(--Dark-Blue);
              }
              .clinician-time{
                color: var(--Dark-Blue);
              }

            }
            .add-it-to-calendar-container{

            }
          }
        }

        .clinician-appointment-wrapper{
          padding: var(--medium) 0;
        }
      }

      .continue-button-container{
        display: flex;
        width: 100%;
        max-width: 280px;
        margin: var(--large) auto 15px auto;
        align-items: center;
        justify-content: center;

        .continue-button{
          --border-radius: 40px;
          width: 280px;
        }

        .continue-button:hover {
          color: var(--Green);
          &::part(native) {
            border: solid 2px var(--Green);
          }
        }
      }
    }

  }
}