
.public-video-page-component{
  .public-video-content{

    .public-video-container {
      height: calc(100vw / 1.7777777);
      max-height: 60vh;
      min-width: 100%;
    }
    .public-video-content-container{
      padding: 5rem 8vw;

      .public-title{
        font-weight: 500;
        font-size: 1.6rem;
        margin: 0;

        @media (min-width: 768px) {
          font-size: 2rem;
        }
      }
      .public-duration{
        margin: 1rem 0;
        color: #555555;
        font-size: 1.5rem;
        font-weight: 400;
      }
      .public-description{
        margin: 0 0 23px 0;
        color: #000000;
        font-size: 1.5rem;
        line-height: 1.4;
        font-weight: 400;
      }
    }
  }
}
