.daily-journey-component {

    &:last-child {
        .divider {
            display: none;
        }
    }

    .two-panel-container{
        width: 100%;
        padding: var(--medium) 2rem 0;        display: flex;
        flex-direction: column;
        @media (min-width: 768px) {
            padding: var(--large) var(--extra-large) 0;
            display: flex;
            flex-direction: row;
        }

        .left-panel{
            flex: 1;
            max-width: 830px;
            padding-bottom: var(--extra-large);

            @media (min-width: 768px) {
                padding-right: 2rem;
            }

            .more-text-button-container{
                width: 100%;
                display: flex;
                justify-content: start;
                margin: 2.5rem 0 0 0;
                .more-text-button{
                    text-transform: capitalize;
                    padding: 0;
                    margin: 0;
                    color: var(--Green);
                    &::part(native){
                        --padding-start: 0;
                    }
                }
                .expand-more-icon{
                    margin-left: var(--unit);
                    width: 15px;
                    height: 12px;
                }
            }

            .title-date-container{
                .date{
                    margin-top: var(--unit);
                    padding: 0;
                }
            }

            .mux-audio-player{
                padding: 25px 0 20px 0;
                &.less-padding{
                    padding: 25px 0 0 0;
                }
            }


            > .inner {
                // max-width: 600px;

                padding: 2.5rem 0 0 0;
                @media (min-width: 700px) {
                    padding: var(--medium) 0 0 0;
                }
            }

            .video-info {
                width: 100%;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                padding-bottom: 20px;
                @media (min-width: 700px) {
                    padding: 40px 0;
                }

                img {
                    height: 300px;
                    width: 300px;
                    border-radius: 150px;
                }

                .column {
                    box-sizing: border-box;
                    width: 100%;
                    @media (min-width: 600px) {
                        width: 50%;
                    }
                }

                .column.info {
                    padding: 0;
                    @media (min-width: 700px) {
                        padding-left: 40px;
                        padding-top: 40px;
                    }
                }
            }




        }
        .right-panel{
            flex: 1;
            @media (min-width: 768px) {
                display: block;
                padding-left: 2rem;
                .daily-journey-video {
                    padding-bottom: 2rem;
                    .daily-video-container{
                        .mux-video-player-component{
                            display: block;
                            .next-recommendations-component{
                                .next-recommendations-wrapper{
                                    width: 100%;
                                    height: 100%;
                                    padding: 0 8px;
                                }
                            }
                        }
                    }

                }
            }

            .daily-journey-resources-help-tiles-container{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                max-width: 600px;
                width: 100%;
                padding-bottom: var(--large);

                @media (min-width: 768px) {
                    width: 40vw;
                    margin-left: 1rem;
                }
                .resources-home-tile-component{
                    margin: 0;
                    padding: 1rem 0;
                }
            }
        }
    }

    .divider {
        height: 2px;
        margin: 0 2rem;
        background: rgb(126,129,213);
        background: linear-gradient(90deg, rgba(126,129,213,1) 0%, rgba(111,196,214,1) 100%);
        width: 96vw;
    }
}
