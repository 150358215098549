.progress-indicator-component{
    height: 100%;
    width: 100%;
    display: inline-flex;
    align-items: center;
    .full-circle{
        display: flex;
        border-radius: 50%;
        max-height: 22px;
        max-width: 22px;
        height: 22px;
        width: 22px;
        align-items: center;
        justify-items: center;
    }
    .rectangle{
        width: 15px;
        height: 6px;
        display: block;
    }
    .green-no-gradient{
        background-color: var(--Green) !important;
    }
    .beige-no-gradient{
        background-color: var(--Beige);
    }
    .half-circle-container{
        display: flex;
        align-items: center;
        justify-content: center;
        max-height: 22px;
        max-width: 22px;
        height: 22px;
        width: 22px;
        .half-circle{
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: var(--White);
        }
    }
}