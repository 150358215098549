.content-division-page{

  .resources-tiles-wrapper{
    padding: 24px 24px;
    @media (min-width: 768px) {
      padding: 27px 41px;
    }

    .resources-tiles-container{
      align-self: center;
      display: grid;
      gap: 1.5rem;
      grid-template-columns: 1fr;
      @media (min-width: 540px) {
        grid-template-columns: 1fr 1fr
      }
      @media (min-width: 768px) {
        margin-top: 1.5rem;
        grid-template-columns: 1fr 1fr 1fr;
      }
      @media (min-width: 1300px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }

    }
  }
}