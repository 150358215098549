.profile-menu-component {
    width: 256px;
    // height: 250px;
    position: absolute;
    right: 0;
    top: 100%;
    box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.15);
    border-radius: 0 0 0 15px;
    z-index: 11;

    .profile-menu-list {
        //padding: 0 0 2rem;
    }


    .profile-menu-toolbar{
        --background: var(--White);
        border-radius: 0 0 0 15px;
        --border-width: 0 0 0 0 !important;
    }

    ion-item::part(native){
        --border-width: 0;
        --border-style: none;
    }

}