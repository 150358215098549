.clinician-calendar-component{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @media (min-width: 768px) {
        flex-direction: row;
    }
    .calendar{
        width: 100%;
        --background: var(--White);
        max-width: 100%;
        @media (min-width: 768px) {
            width: calc(50% - var(--extra-large)/2);
        }
        .calendar-component{
            width: 100%;
            .calendar-header{
                width: 100%;
                display: flex;
                justify-content: space-between;
                .month-year-text{
                    color: var(--Dark-Blue);
                }
                .next-prev-month-container{
                    display: flex;
                    align-items: flex-start;
                    .next-prev-month-button{
                        color: var(--Green);
                        &::part(native){
                            --padding-start: 0;
                            --padding-end: 0;
                            --padding-top: 5px;
                            height: auto;
                        }
                    }
                    .next-prev-month-button:first-of-type{

                        &::part(native){
                            --padding-end: 10px;
                        }
                    }
                }
            }
            .calendar-body{
                display: flex;
                flex-direction: column;
                width: 100%;
                .days-text-container{
                    display: flex;
                    padding: 0 0 var(--unit) 0;
                    .days-text{
                        text-align: center;
                        width: calc(20%);
                    }
                }
                .weeks-container{
                    display: flex;
                    flex-direction: column;
                    padding: var(--unit) 0 0 0;
                    .week{
                        width: 100%;
                        display: flex;
                        padding: 5px 0;
                        &.first{
                            padding-top: 0;
                            justify-content: flex-end;
                        }
                        .slot{
                            width: calc(20%);
                            height: 60px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            .button-container{
                                width: 60px;
                                height: 60px;
                                .day-button{
                                    width: 100%;
                                    height: 100%;
                                    border: 3px solid var(--Gray);
                                    border-radius: 14px;
                                    color: var(--Gray);
                                    --background: var(--White);
                                    margin: 0;
                                    &::part(native){
                                        --padding-start: 0;
                                        --padding-end: 0;
                                        --padding-top: 0;
                                        --padding-bottom: 0;
                                        border-radius: 14px;
                                    }
                                    &:hover{
                                        border: 3px solid var(--Green);
                                        transform: scale(1.02);
                                        transition: ease-in-out;
                                        transition-duration: .3s;
                                    }
                                    &.selected{
                                        color: var(--Green);
                                        border: 3px solid var(--Green);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .time-slots-buttons-container{
        padding-top: var(--small);
        @media (min-width: 768px) {
            padding-top: 0;
            width: calc(50% - var(--extra-large)/2);
        }
        .date-text{
            color: var(--Dark-Blue);
            padding-bottom: 20px;
        }
        .buttons-container{
            .availability-button{
                --background: var(--White);
                border-radius: 40px;
                border-style: solid;
                border-width: 1px;
                border-color: var(--Gray);
                margin: 7px 0;
                &::part(native){
                    border-radius: 40px;
                    --padding-start: 0;
                    --padding-end: 0;
                    --padding-top: 0;
                    --padding-bottom: 0;
                }
                &:hover{
                    color: var(--Green);
                    border: 2px solid var(--Green);
                    transform: scale(1.02);
                    transition: ease-in-out;
                    transition-duration: .3s;

                }
            }
        }
    }
}