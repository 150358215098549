.benefits-component {
    min-height: calc(100vh - 370px);
    .welcome-text-container{
        padding: var(--large) 2rem var(--small);
        @media (min-width: 768px) {
            padding: var(--large) var(--extra-large) var(--medium);
        }
        .welcome-text-body{
            max-width: 1065px;
        }
    }

    .benefits-header-container{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        .benefits-header {
            background: var(--White);
            width: 100%;
            max-height: 268px;
            max-width: 390px;
            @media (min-width: 768px) {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }

    .benefits-panel-container{
        display: inline-block;
        @media (min-width: 768px) {
            display: flex;
        }

        .company-benefits-panel{
            padding: var(--medium) 2.5rem;
            width: 100%;

            @media (min-width: 768px) {
                padding: 0 var(--extra-large) var(--medium);
                width: 50%;
            }
        }
        .standard-benefits-panel{
            padding: var(--medium) 2.5rem;
            width: 100%;

            @media (min-width: 768px) {
                padding: 0 var(--extra-large) var(--medium);
                width: 50%;
            }

        }
        .benefits-resources-help-tiles-container{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            max-width: 600px;
            width: 100%;
            padding-bottom: var(--medium);
            @media (min-width: 1240px) {
                flex-direction: row;
            }

            .resources-home-tile-component{
                margin: 0;
                padding: 1rem 0;
                @media (min-width: 1240px) {
                    padding: 1rem;
                }
            }
        }

        .benefits-list{

        }
        .benefits-page-line-divider-container{
            .benefits-page-line-divider{
                height: 1.5px;
                min-height: 1px;
                background: var(--Beige);
            }
        }
    }
    .benefits-vertical-line-divider-container{
        display: none;
        @media (min-width: 768px) {
            display: flex;
            margin-bottom: var(--medium);
        }
        .benefits-vertical-line-divider{
            padding: 0;
            width: 1.5px;
            height: 100%;
            background: var(--Beige);

        }
    }

}
