.privacy-policy-page{
  overflow-y: scroll;
  @media (min-width: 768px){
    overflow-y: visible;
  }
  .privacy-policy-content{
    --background: white;
    .privacy-policy-text{
      padding: 24px;
      color: #000000;
      line-height: 24px;
      font-size: 18px;
    }
  }
}