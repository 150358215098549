.assessment-modal-component{
  --background: #ffffff;
  --border-radius: 16px;
  --width: 100%;
  --height: 100%;
  @media (min-width: 768px) {
    --width: 700px;
    --height: 65%;
  }
  @media (min-width: 1024px) {
    --width: 910px;
    --height: 65%;
  }
  .assessment-modal-wrapper{
    --background: #ffffff;
  }

  .top-container{
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 22px;
    margin: var(--extra-large) 0 0 0;
    @media (min-width: 768px) {
      margin: var(--large) 0 0 0;
    }
    .back-button-container{
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: var(--extra-small);
    }
    .progress-bar-container{
      height: 22px;
      //margin right = back button container width + margin
      margin: 0 40px 0 0;
    }
  }
  .exit-button-container{
    width: 25px;
    height: 25px;
    position: absolute;
    right: 15px;
    top: 15px;
  }
  .bottom-container{
    max-width: 480px;
    margin: 0 auto;
    @media (min-width: 768px) {
      max-width: 600px;
    }
    .assessment-welcome-step{
      margin: 70px 0 0 0;
    }
    .assessment-finished-step{
      margin: 70px  0 0 0;
    }

    .journey-checkmark-container{
      width: 40px;
      margin: 0 auto;
    }

    .journey-logo-container{
      width: 111px;
      margin: 0 auto 0 auto;
    }
    .assessment-title{
      text-align: center;
      margin: var(--large) 0 0 0;
      @media (min-width: 768px) {
        margin: var(--extra-small) 0 0 0;
      }
    }
    .assessment-text{
      text-align: center;
      margin: 16px var(--extra-small) 0;
      max-width: 350px;
      @media (min-width: 375px) {
        margin: var(--extra-small) calc((100% - 350px)/2) 0;
      }

    }
  }

  .assessment-continue-button-container{
    display: flex;
    width: 100%;
    max-width: 280px;
    margin: var(--large) auto 15px auto;
    align-items: center;
    justify-content: center;

    .assessment-continue-button{
      --border-radius: 40px;
      width: 280px;
      ion-icon{
        margin-left: var(--unit);
        width: 15px;
        height: 15px;
      }
    }

    .assessment-continue-button:hover {
      color: var(--Green);
      &::part(native) {
        border: solid 2px var(--Green);
      }
    }
  }
  .continue-button-container{
    text-align: center;
    margin-bottom: 30px;
    .continue-button{
      margin: 26px 0 30px 0;
      width: calc(100% - 90px);
      height: 40px;
      border-radius: 4px;
      @media (min-width: 768px) {
        margin: 36px 0 30px 0;
        width: calc(100% - 372px);
        height: 45px;
      }
    }
  }
}