.copy-to-clipboard-modal{
  --background: #ffffff;
  --border-radius: 6px;
  --height: 200px;
  --width: 300px;
  @media (min-width: 768px) {
    --width: 531px;
    --height: 171px;
  }
  .body-container{
    color: var(--Dark-Blue);
    margin: 0;
    padding: var(--extra-small) var(--unit) 0 var(--unit);
    text-align: center;
    .item-container{
      ion-item::part(native){
        --padding-start: 5px;
        @media (min-width: 768px) {
          --padding-start: 20px;
        }
        .item-inner{
          padding-left: 40px;
          .input-wrapper{
            justify-content: space-between;
          }
        }
      }
      .clipboard-item{
        margin: var(--unit) 1% 0 1%;
        width: calc(100% - 2%);
        border: 1px solid #CECECE;
        border-radius: 8px;
        --inner-border-width: 0;
        color: var(--Dark-Blue);
        @media (min-width: 768px) {
          margin: var(--unit) 10% 0 10%;
          width: calc(100% - 20%);
        }
        .clipboard-item-container{
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .clipboard-text{
            padding: 7px 0;
          }
        }
      }
    }

    .clipboard-button{
      color: var(--White);
      margin-top: 15px;
      margin-bottom: 0;
      @media (min-width: 768px) {
        height: 30px;
        margin-top: 0;
      }
    }
    .clipboard-button:hover{
      color: var(--Green);
      .share-button-icon{
        filter: invert(0%) sepia(3%) saturate(7487%) hue-rotate(288deg) brightness(83%) contrast(98%);
      }
      &::part(native) {
        border: solid 2px var(--Green);
      }
    }
  }
}