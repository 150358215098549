.monthly-assessment-popover-component{
    .take-assessment-button{
        max-width: 193px;
        margin: var(--extra-small) 0 2rem 0;
        color: var(--White);
        &::part(native) {
            border: solid 2px var(--Green);
        }
        @media (min-width: 768px) {
            margin: 0 var(--unit);
        }
    }
    .take-assessment-button:hover{
        color: var(--Green);

        &::part(native) {
            border: solid 2px var(--Green);
        }
    }
}