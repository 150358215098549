.settings-page{
  .settings-page-content{
    height: 100%;
    .buttons-container{
      display: flex;
      flex-direction: column;
      height: 100%;
      .privacy-policy-button{
        display: flex;
        justify-content: flex-start;
        width: fit-content;
      }
    }
  }
}