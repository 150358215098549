.category-page {

  .category-content-container{

    .category-header {
      padding: var(--large) 2rem;
      @media (min-width: 768px) {
        display: flex;
        align-items: flex-start;

        img {
          order: 2;
          max-width: 40vw;
        }
      }

      @media (min-width: 1024px) {
        padding: var(--extra-large);
      }

      .category-title-description-container{
        @media (min-width: 768px) {
          margin-right: var(--medium);
        }
        @media (min-width: 1024px) {
          margin-right: var(--large);
        }
        .category-title{

        }

        .clamp-description{
          padding: 2rem 0 0 0;
        }
      }

    }

    .category-content {
      padding: 2rem;
      max-width: 400px;
      margin: 0 auto;
      @media (min-width: 665px) {
        display: grid;
        max-width: 750px;
        grid-template-columns: repeat(2, 50%);
        grid-template-rows: repeat(auto-fill, 1fr);
      }
      @media (min-width: 1024px) {
        max-width: 1300px;
        display: grid;
        grid-template-columns: repeat(3, 33.33%);
        grid-template-rows: repeat(auto-fill, 1fr);
      }
      @media (min-width: 1400px) {
        max-width: none;
        padding: var(--extra-large);
        grid-template-columns: repeat(4, 25%);
        grid-template-rows: repeat(auto-fill, 1fr);
      }

      .content-tile {
        margin: 0 0 var(--extra-large) 0;
        min-height: 200px;
        aspect-ratio: 300/200;
      }
      //Styling for grid of 2 columns
      .content-tile:nth-of-type(odd){
        @media (min-width: 665px) {
          margin: 0 1rem var(--extra-large) 0;
        }
      }
      .content-tile:nth-of-type(even){
        @media (min-width: 665px) {
          margin: 0 0 var(--extra-large) 1rem;
        }
      }

      //Styling for grid of 3 columns
      //4rem has to be split evenly between the three columns 4/3 = 1.33rem
      .content-tile:nth-of-type(3n+1){
        @media (min-width: 1024px) {
          margin: 0 1.33rem var(--extra-large) 0;
        }
      }
      .content-tile:nth-of-type(3n+2){
        @media (min-width: 1024px) {
          margin: 0 0.66rem var(--extra-large) 0.66rem;
        }
      }
      .content-tile:nth-of-type(3n+3){
        @media (min-width: 1024px) {
          margin: 0 0 var(--extra-large) 1.33rem;
        }
      }

      //Styling for grid of 4 columns
      //12rem has to be split evenly between the 4 columns, 12/4 = 3rem
      .content-tile:nth-of-type(4n+1){
        @media (min-width: 1400px) {
          margin: 0 3rem var(--extra-large) 0;
        }
      }
      .content-tile:nth-of-type(4n+2){
        @media (min-width: 1400px) {
          margin: 0 2rem var(--extra-large) 1rem;
        }
      }.content-tile:nth-of-type(4n+3){
         @media (min-width: 1400px) {
           margin: 0 1rem var(--extra-large) 2rem;
         }
       }
      .content-tile:nth-of-type(4n+4){
        @media (min-width: 1400px) {
          margin: 0 0 var(--extra-large) 3rem;
        }
      }
    }
  }
}