.please-wait-component {
    background-color: white;
    height: 100%;
    &.transparent{
        background-color: transparent;
    }
    > .content {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        height: 90%;
        > h2 {
            color: rgb(139,199,237);
            margin-bottom: 32px;
        }

        > .loading-spinner {
            color: rgb(139,199,237);
        }
    }
}