.benefit-component {
    max-width: 500px;
    background-color: white;
    padding: var(--medium) 0;
    text-align: start;
    box-sizing: border-box;
    img {
        width: 100%;
        max-width: 200px;
    }

    .benefit-name {
        padding-top: 2rem;
    }
}
