.languages-menu-list {
    .menu-item{
        cursor: pointer;

        @media (max-width: 768px) {
            width: calc(100% - 32px);
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-right: none;
            border-left: none;
            border-top: none;
            --min-height: 30px;
        }
        &:nth-child(2) {
            border-bottom: 1px solid #333;
        }
    }

    .selected {
        --background: #E9E9E9;
    }

    .language {
        cursor: pointer;
        font-family: 'Albert Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #282828;
    }

    .menu-icon{
        width: 15px;
        height: 15px;
        cursor: pointer;
        --fill: #4A4A4A;
    }

    ion-item::part(native){
        --border-width: 0;
        --border-style: none;
    }
    ion-item::part(detail-icon){
        color: #000;
        --detail-icon-opcaity: 0;
    }
}