.image-carousel {
    position: relative;
    // padding-bottom: 2rem;

    .arrow-button{
        position: absolute;
        width: 4rem;
        height: calc(100% - 26px - 10px);
        margin: 0;
        --padding-start: 0;
        --padding-end: 0;
        color: #000000;
        &.left{
            left: 0;
            transform: rotate(180deg);
        }
        &.right{
            right: 0;
        }
        .arrow-icon{
            width: 15px;
            height: 22px;
        }

        &.hide-arrow {
            visibility: hidden;
        }
        &::part(native){
            --padding-start: 0;
            --padding-end: 0;
            --padding-top: 0;
            --padding-bottom: 0;
        }
    }

    .swiper {
        white-space: nowrap;
        width: 100%;
        @media (min-width: 768px) {
            padding: 2rem var(--unit) 0;
            //width = 100% - arrow buttons width
            width: calc(100% - (var(--medium))*2);
        }

        .image-carousel-tile {
            max-width: 300px;
            aspect-ratio: 300/200;
        }
    }
}