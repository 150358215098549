.teacher-style-component{
    border-radius: 16px;
    height: 100%;
    .image-container {
        position: relative;
        height: 100%;
        background-size: cover;
        border-radius: 16px;
        aspect-ratio: var(--aspect-ratio);

        .image{
            max-height: 100%;
            position: absolute;
            right: 0;
            bottom: 0;
            border-bottom-right-radius: 16px;
        }

        .tile-name-container{
            width: calc(100% - (var(--extra-small)*2));
            display: inline-block;
            padding: var(--extra-small) 0;
            margin: 0 var(--extra-small);
            border: var(--Gray);
            border-style: solid;
            border-width: 0 0 1px 0;
            .tile-name{
                text-transform: uppercase;
                width: 100%;
                color: var(--Gray);
            }
        }
        .author-container{
            padding: var(--unit) 0 0 var(--extra-small);
            position: inherit;
            z-index: 1;
            .author-name{
                position: inherit;
                text-transform: capitalize;
            }
        }
        &:hover {
            .add-to-playlist-container{
                display: block;
                width: 24px;
                height: 24px;
                top: var(--unit);
                right: var(--extra-small);

                @media (min-width: 1200px) {
                    width: 30px;
                    height: 30px;
                }
            }
        }
        .add-to-playlist-container{
            position: absolute;
            display: none;
        }
    }

    .blue{
        background: #77CAF1;;
        border-radius: 16px;
    }

    .purple{
        background: #D8BBFF;
        border-radius: 16px;
    }

    .yellow{
        background: #FFCE6F;
        border-radius: 16px;
    }

    .green{
        background: #4EDED6;
        border-radius: 16px;
    }
}

