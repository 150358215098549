.next-recommendations-component{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .next-recommendations-wrapper{
    z-index: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: absolute;
    padding: 0 5px;
    top: 0;
    left: 50%;
    transform: translate(-50%,0);
    max-height: 60vh;
    aspect-ratio: 16/9;
    background: rgba(0, 0, 0, .6);
    &:hover {
      background: transparent;
    }
    width: 100%;
    height: 100%;

    @media (min-width: 768px) {
      height: 100%;
      width: auto;
    }

    .next-recommendations-top-container{
      width: 100%;
      grid-template-columns: 45vw 5vw;
      justify-content: center;
      display: grid;
      &.small-player{
        grid-template-columns: .65fr .05fr;
      }
      @media (min-width: 768px) {
        grid-template-columns: 95fr 5fr;
        width: 100%;
      }
      .next-recommendations-text{
        display: flex;
        align-items: center;
        color: var(--White);
        padding: 15px 0 5px 0;
        text-align: left;
        @media (min-width: 768px) {
          &.small-player {
            font-size: 15px;
            line-height: 23px;
          }
        }
      }

      .next-recommendations-exit-container{
        display: flex;
        flex-direction: column-reverse;
        margin: 0 0 5px 0;
      }
    }

    .recommendations-container{
      align-self: center;
      display: grid;
      gap: 1.5rem;
      justify-content: center;
      width: 95%;
      &.one{
        grid-template-columns: .7fr;
      }
      &.two{
        grid-template-columns: 1fr 1fr;
      }
      &.three, &.four, &.five, &.six{
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr;
      }
      .content-tile{
        aspect-ratio: 300/200;
      }
    }
  }
}