.success-message-component {
    .success-icon {
        height: 100px;
        margin: 30px auto;
        display: block;
    }

    .content {
        margin-top: 40px;
        text-align: center;
    }
}
