.article-preview-component{
  padding: 0;
  margin: 0;

  .article-preview-container{
    border: 1px solid #D3D3D3;
    border-radius: 6px;
    width: 100%;
    max-height: 178px;
    display: flex;
    flex-direction: column;
    padding: 20px 16px;

    .article-preview-title{
      color: var(--Dark-Blue);
      line-height: 19px;
      padding: 0;
    }
    .article-preview-truncated-body{
      color: var(--Dark-Blue);
      margin: 0 0 16px 0;
      height: 68px;
      text-overflow: ellipsis;
      overflow: hidden;

    }
    .article-preview-read-more-button-container{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      .article-preview-read-more-button{
        margin: 0;
        height: 33px;
        color: var(--White);
      }
      .article-preview-read-more-button:hover{
        color: var(--Green);

        &::part(native) {
          border: solid 2px var(--Green);
        }
      }
    }
  }
}