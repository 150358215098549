.personal-plan-page {
    background: var(--Beige);
    font-family: 'Montserrat-Medium';

    .personal-plan-content {
        --ion-background-color: transparent;
    }

    .page-header {
        padding: 4rem;
        display: flex;
        background-color: white;
        align-items: center;
        .header {
            font-size: 6.4rem;
            color: var(--Dark-Blue);
        }

        .copy {
            font-family: 'Montserrat-Regular';
            line-height: 1.4;
            font-size: 2.2rem;
            color: var(--Dark-Blue);
            flex-shrink: 0;
        }

        .title-container {
            flex: 1;
            padding-right: 10rem;
        }

        .dashboard-container {
            flex-shrink: 0;
            padding: 1rem 2rem;
            box-shadow: 0 4px 4px 2px #eee;
            border-radius: 16px;
            color: var(--Gray);
            min-width: 300px;

            h3 {
                font-family: 'Montserrat-Regular';
                font-size: 2rem;
                font-weight: 600;
                padding: 1rem 0;
                margin: 0;
                border-bottom: 0.75px solid #555;
            }

            .row {
                display: flex;
                padding: 1.4rem 0;
                border-bottom: 0.75px dashed #555;

                &:last-child {
                    border-bottom: none;
                }

                h4 {
                    flex: 1;
                    font-family: 'Montserrat-Regular';
                    text-transform: uppercase;
                    font-size: 1.5rem;
                    font-weight: 600;
                    margin: 0;
                }

                span {
                    font-size: 1.5rem;
                    color: var(--Dark-Blue);
                }
            }
        }
    }

    .page-content {
        padding: 4rem;

        .accordion {
            background-color: white;
            border-radius: 16px;
            box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
            margin-bottom: 4rem;

            &.open {
                header .down-icon {
                    transform: rotate(180deg);
                    transition: transform 0.3s ease-in-out;
                }

                .content {
                    display: flex;
                }
            }

            header {
                display: flex;
                align-items: center;
                padding: 3rem 2rem;
                cursor: pointer;

                .week-chip {
                    display: flex;
                    align-items: center;
                    font-size: 2rem;
                    color: white;
                    padding: 0.5rem 1rem;
                    font-weight: 600;
                    font-family: 'Montserrat-Medium';
                    border-radius: 10px;

                    ion-icon {
                        margin-right: 0.5rem;
                    }

                    &.completed {
                        background: #4EDED6;
                    }

                    &.in-progress {
                        background: linear-gradient(90deg, #7E82DB 0%, #49C9D8 100%);
                    }

                    &.disabled {
                        background: #555;
                    }
                }

                h2 {
                    flex: 1;
                    font-family: 'Montserrat-Regular';
                    font-weight: 600;
                    margin: 0;
                    color: #555;
                    font-size: 3rem;
                    margin-left: 2rem;
                }




            }

            .content {
                display: none;
                padding: 1rem 0rem 4rem;
                margin: 0 2rem;
                overflow-x: auto;

                .video-thumbnail {
                    width: 300px;
                    aspect-ratio: 300/200;
                    margin-right: 2rem;
                }
            }
        }
    }
}