.radio-button-assessment-question-component{

  .radio-button-assessment-question-copy{
    display: flex;
    margin: 0 auto;
    text-align: center;
    justify-content: center;
    @media (min-width: 768px) {
      align-self: flex-start;
      margin: 0;

    }
  }
  .radio-button-assessment-items-container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 2rem;
    .radio-button-group{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    .radio-button-assessment-checkbox-item{
      border: 1px solid var(--Gray);
      border-radius: 40px;
      width: 100%;
      height: var(--large);
      margin: 7px 0;
      --padding-start: 16px;
      --inner-border-width: 0px;
      &::part(native){
        --border-radius: 40px;
        height: var(--large);
        --background-hover: var(--White);
        --background-hover-opacity: 0;
      }
      &.selected{
        transform: scale(1.02);
        transition: ease-in-out;
        transition-duration: .3s;
        color: var(--Green);
        border: 1.5px solid var(--Green);
        --border-color: 1.5px solid var(--Green);
      }
      &.more-bottom-padding{
        margin: 6px 0 20px 0;
        @media (min-width: 768px) {
          margin: 6px 0 27px 0;
        }

      }
      .radio-button-assessment-checkbox{
        display: flex;
        align-items: center;
        margin: 0;
        width: 25px;
        height: 25px;
        --color-checked: #fff;

        &.radio-checked{
          &::part(container){
            border-radius: 50%;
            width: 25px;
            height: 25px;
            background: var(--Green);
            border: 1px solid rgba( 0, 0, 0, 0);
          }
        }
        &::part(mark){
          border-width: 0 3px 3px 0;
          width: 7px;
          height: 14px;
          margin: 0 1px 3px 0;
        }
      }
      .radio-button-assessment-label{
        //Padding is to handle the "center" offset
        padding: 0 32px 0 0;
        text-align: center;
        display: block;
        &.selected{
          color: var(--Green);
        }

      }
    }
    .radio-button-assessment-checkbox-item:hover{
      border: 1px solid var(--Green);
      .radio-button-assessment-label{
        color: var(--Green);
      }
    }
  }
}