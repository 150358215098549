ion-modal.eap-support-phones-modal::part(content){
    max-width: 600px;
    width: 100%;
    border-radius: 6px;

    @media (min-width: 550px) {
      width: 80vw;
    }
}

ion-modal.eap-support-phones-modal {
  --height: fit-content;
  width: 100%;
}

.eap-support-phones {
    .eap-support-exit-button-container{
        background: #fff;
        display: flex;
        justify-content: flex-end;
        margin-left: 20px;
        margin-right: 20px;

        > .exit-button-component{
        top: 10px;
        right: 12px;
        position: absolute;
        }
    }

    .eap-support-component{
        display: flex;
        font-size: 1.2rem;
        background-color: #fff;
        height: 100%;
        overflow: hidden;

        .eap-support-container {
            display: flex;
            align-items: center;
            flex-direction: column;
            padding: 2rem;
            margin: auto;

            h2 {
                color: #000000;
                font-family: 'Albert Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 26px;
                text-align: center;
                max-width: 400px
            }

            h3 {
                color: #000000;
                font-family: 'Albert Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 26px;
                margin: auto;
                @media (max-width: 410px) {
                    font-size: 14px;
                }
                @media (max-width: 375px) {
                    font-size: 13px;
                }
            }

            .phonesComponent {
                margin-top: 20px;

                .phoneComponent {
                    text-decoration: none;
                    display: flex;
                    border: 1px solid #CFCFCF;
                    border-radius: 4px;
                    margin-bottom: 12px;
                    height: 45px;
                    width: 390px;
                    cursor: pointer;
                    @media (max-width: 430px) {
                        width: 360px;
                    }
                    @media (max-width: 395px) {
                        width: 340px;
                    }
                    @media (max-width: 370px) {
                        width: 100%;
                    }
                }
            }
        }

        @media (min-width: 768px) {
            font-size: 1.1rem;
            align-items: stretch;
            height: 100%;
        }
    }
}