.back-button{
  height: 18px;
  width: 18px;
  margin: 0;
  border-radius: 0;
  &::part(native){
    --padding-start: 0 !important;
    --padding-end: 0 !important;
    --padding-top: 0 !important;
    --padding-bottom: 0 !important;
  }
}
