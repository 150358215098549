.header{
  --height: 77px;

  .toolbar-component{
    --ion-border-color: rgba(0, 0, 0, 0.1);
    --background: #FFFFFFFF;
    --border-width: 0;
    --padding-start: 0;
    --padding-end: 0;
    --padding-top: 0;
    --padding-bottom: 0;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
    height: 77px;
    &.new-homepage {
      --border-width: 0;
    }

    .toolbar-container {
      display: flex;
      height: 77px;
      width: 100%;
      justify-content: space-between;

      .toolbar-logo-container{
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0 var(--small);

        .logo-image{
          width: 111.51px;
          height: 37.78px;
        }
      }

      .toolbar-buttons{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        flex: 1;
        .toolbar-button{

        }
        .selected {
          border-bottom: 3px solid var(--Dark-Blue);
          --color: var(--Dark-Blue);
        }

        .login-button{
          --color: var(--Green);
        }

        &.toolbar-buttons-auth {
          justify-content: flex-end;
          flex: 0;
          .row {
            display: flex;
            flex-direction: row;
            padding: 0 1rem;
          }
        }
      }
    }
  }
}