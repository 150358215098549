.activity-calender-component{
    //Box styling is in homepage.scss
    .activity-calender {
        height: 100%;

        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr;
        padding: 0;

        &.rows-5 {
            grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
        }

        &.rows-6 {
            grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
        }

        .calendar-day {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            line-height: 2.4;
            margin: 0.2rem 0.2rem;

            &.out-of-month {
                color: var(--Beige);
            }

            &.active {
                background-color: var(--Green);
                border-radius: 28px;


                &:nth-child(7n + 1) {
                    margin-left: 0;
                    padding-left: 0;
                }
            }
            &.continuous-left-radius{
                margin-left: -0.1rem;
                padding-left: 0.1rem;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
            &.continuous-right-radius{
                margin-right: -0.1rem;
                padding-right: 0.1rem;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }
}