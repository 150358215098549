.sign-up-form-error-component {
    overflow-y: hidden;
    width: 100%;
    margin: 0 auto;
    @media (min-width: 768px){
      width: 700px;
    }
    .sign-up-title{
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: #555555;
    }
    .email-notice {
        width: 100%;
        text-align: center;
        margin-bottom: 30px;
        margin-top: 5px;
        display: inline-block;
        font-size: 16px;
        color: #66818f;
    }
  }
