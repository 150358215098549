.resources-tile-component{
  // padding: 14px;
  .resources-tile{
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 4px;
    width: 100%;
    aspect-ratio: 16/9;
    padding: 2vw;
    position: relative;
    &.bordered .native-name-container{
      border: 3px solid #242424;
    }
    .resources-tile-image{
      position: absolute;
      bottom: 0;
      right: 2.5vw;
      height: 90%;
    }

    // padding: 22px;
    .native-name-container{
      padding-left: 0.2rem;
      background: transparent;
      width: calc(100% - 0px);
      height: calc(100% - 0px);
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      color: #000000;
      word-break: break-word;
      text-align: center;
      &.align-left{
        align-items: flex-start;
        padding-left: 15%;
      }
    }
    &.blue{
      background: linear-gradient(90.04deg, #FFFFFF 8.07%, #77CAF1 99.96%);
      border-radius: 16px;
    }

    &.purple{
      background: linear-gradient(90deg, #FFFFFF 0%, #D8BBFF 100%);
      border-radius: 16px;
    }

    &.yellow{
      background: linear-gradient(90deg, #FFFFFF 0%, #FFCD70 100%);
      border-radius: 16px;
    }

    &.green{
      background: linear-gradient(90.04deg, #FFFFFF 15.73%, #94EBE5 99.96%);
      border-radius: 16px;
    }

    &.pink{
      background: linear-gradient(90deg, #FFFFFF 0%, #F5C0C7 100%);
      border-radius: 16px;
    }
  }
}