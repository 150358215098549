
.theme-style-component{
    background: var(--Beige);
    border-radius: 16px;
    height: 100%;
    .image-container {
        position: relative;
        height: 100%;
        background-size: cover;
        border-radius: 16px;
        aspect-ratio: var(--aspect-ratio);

        .image{
            max-height: 100%;
            width: auto;
            position: absolute;
            right: 0;
            bottom: 0;
            border-bottom-right-radius: 16px;
            border-top-right-radius: 16px;
        }

        .tile-name-container{
            width: calc(60% - (var(--extra-small)*2));
            display: inline-block;
            padding: var(--extra-small) 0;
            margin: 0 var(--extra-small);
            border: var(--Gray);
            border-style: solid;
            border-width: 0 0 1px 0;
            .tile-name{
                text-transform: uppercase;
                width: 100%;
                color: var(--Gray);
            }
        }
        .title-container{
            padding: var(--unit) 0 0 var(--extra-small);
            position: inherit;
            z-index: 1;
            //width = 60% - padding
            width: calc(60% - var(--extra-small));
            .title{
                position: inherit;
                text-transform: capitalize;
                color: var(--Dark-Blue);
                white-space: normal;
                width: 100%;
                display: inline-block;
                overflow-wrap: break-word;
                word-wrap: break-word;
            }
        }
        &:hover {
            .add-to-playlist-container{
                display: block;
                width: 24px;
                height: 24px;
                top: var(--unit);
                right: var(--extra-small);

                @media (min-width: 1200px) {
                    width: 30px;
                    height: 30px;
                }
            }
        }
        .add-to-playlist-container{
            position: absolute;
            display: none;
        }
    }
}



