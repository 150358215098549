.add-to-playlist-component{
    align-items: center;
    width: 100%;
    height: 100%;

    ion-icon {
        color: white;
        border-radius: 3px;
        padding-left: 4px;
        padding-top: 2px;
        width: 100%;
        height: 100%;
        cursor: pointer;
        shape-rendering: crispEdges;
        background-color: rgba(0,0,0,0.4);
        @media (min-width: 1200px) {
            padding-left: 4px;
        }

        &:hover {
            background-color: rgba(0,0,0,0.7);
        }
    }

    &:hover .tooltip {
        display: block;
    }

    .tooltip {
        display: none;
        font-size: 1.1rem;
        position: absolute;
        text-align: center;
        width: 4rem;
        left: -9.5rem;
        background-color: rgba(0,0,0,0.4);
        color: white;
        padding: 0 0.4rem;
        border-radius: 3px;
        line-height: 1.6;
        margin-right: 0.5rem;
        @media (min-width: 768px) {
            width: 9rem;
        }

        &.remove {
            width: 12rem;
            left: -12.5rem;
        }
    }
}