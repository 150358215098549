.search-page{
  .side-menu-container{
    border-style: solid;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
    border-width: 0 0 1px 0;
    border-color: rgba(0, 0, 0, 0.1);
  }
  .search-page-toolbar{
    --background: #fff;
    box-shadow: #a0a0a0;
    --padding-start: 0;
    --padding-end: 0;
    --padding-bottom: 10px;
    @media (min-width: 768px) {
      filter: none;
    }
    .search-item{
      width: 90%;
      margin: 50px 16px 0 16px;
      height: var(--extra-large);
      --min-height: var(--extra-large);
      //--background: rgba(0, 0, 0, .15);
      border: 3px solid #7E82DB;
      border-radius: 40px;
      --inner-border-width: 0;

      @media (min-width: 768px) {
        width: 400px;
        margin: 100px auto 0;
      }
      .search-input{
        --placeholder-color: var(--Gray);
        --padding-bottom: 15px;
        color: var(--Dark-Blue);
      }
      .search-icon{
        margin: 7px 5px;
        .gray{
          color: var(--Dark-Blue)
        }
      }
      .clear-button{
        width: 27px;
        height: 27px;
        margin-bottom: 2px;
        &::part(native){
          --padding-start: 0;
          --padding-end: 0;
          --padding-top: 0;
          --padding-bottom: 0;
        }
      }
    }
  }
  .search-page-content{
    .search-page-loading-indicator-container{
      text-align: center;
      height: 200px;
      position: relative;
      .search-page-loading-indicator{
        position: absolute;
        --color: #282828;
        transform: scale(5);
        bottom: 50px;
      }
    }
    .search-container {
      margin: 100px auto 0;
      max-width: 400px;
      @media (min-width: 665px) {
        padding: 2.5rem;
        display: grid;
        max-width: 750px;
        grid-template-columns: repeat(2, 50%);
        grid-template-rows: repeat(auto-fill, 1fr);
      }
      @media (min-width: 1024px) {
        max-width: 1300px;
        margin: 100px auto 0;
        display: grid;
        padding: var(--medium);
        grid-template-columns: repeat(3, 33.33%);
        grid-template-rows: repeat(auto-fill, 1fr);
      }
      @media (min-width: 1400px) {
        max-width: none;
        padding: var(--extra-large);
        grid-template-columns: repeat(4, 25%);
        grid-template-rows: repeat(auto-fill, 1fr);
      }

      .content-tile {
        margin: 0 0 var(--extra-large) 0;
        min-height: 200px;
        aspect-ratio: 300/200;
      }
      //Styling for grid of 2 columns
      .content-tile:nth-of-type(odd){
        @media (min-width: 665px) {
          margin: 0 1rem var(--extra-large) 0;
        }
      }
      .content-tile:nth-of-type(even){
        @media (min-width: 665px) {
          margin: 0 0 var(--extra-large) 1rem;
        }
      }

      //Styling for grid of 3 columns
      //4rem has to be split evenly between the three columns 4/3 = 1.33rem
      .content-tile:nth-of-type(3n+1){
        @media (min-width: 1024px) {
          margin: 0 1.33rem var(--extra-large) 0;
        }
      }
      .content-tile:nth-of-type(3n+2){
        @media (min-width: 1024px) {
          margin: 0 0.66rem var(--extra-large) 0.66rem;
        }
      }
      .content-tile:nth-of-type(3n+3){
        @media (min-width: 1024px) {
          margin: 0 0 var(--extra-large) 1.33rem;
        }
      }

      //Styling for grid of 4 columns
      //12rem has to be split evenly between the 4 columns, 12/4 = 3rem
      .content-tile:nth-of-type(4n+1){
        @media (min-width: 1400px) {
          margin: 0 3rem var(--extra-large) 0;
        }
      }
      .content-tile:nth-of-type(4n+2){
        @media (min-width: 1400px) {
          margin: 0 2rem var(--extra-large) 1rem;
        }
      }.content-tile:nth-of-type(4n+3){
         @media (min-width: 1400px) {
           margin: 0 1rem var(--extra-large) 2rem;
         }
       }
      .content-tile:nth-of-type(4n+4){
        @media (min-width: 1400px) {
          margin: 0 0 var(--extra-large) 3rem;
        }
      }
    }
    .results-text{
      text-align: center;
      width: 100%;
      padding: var(--small);
    }
  }
}