@font-face {
  font-family: 'Montserrat-Regular';
  src: local('Montserrat-Regular'),url(./assets/fonts/Montserrat/Montserrat-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat-Medium';
  src: local('Montserrat-Medium'),url(./assets/fonts/Montserrat/Montserrat-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat-SemiBold';
  src: local('Montserrat-SemiBold'),url(./assets/fonts/Montserrat/Montserrat-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: local('Montserrat-Bold'),url(./assets/fonts/Montserrat/Montserrat-Bold.ttf) format('truetype');
}

.typography{

  .header-4 {
    font-family: 'Montserrat-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
    color: var(--Dark-Blue);
    letter-spacing: -0.2px;
  }
  .header-4-variant{
    font-family: 'Montserrat-SemiBold';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.25px;
    color: var(--White);
  }

  .header-5 {
    font-family: 'Montserrat-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 38px;
    color: var(--Dark-Blue);
    letter-spacing: -0.3px;
  }

  .header-5-variant{
    font-family: 'Montserrat-SemiBold';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;
    letter-spacing: -0.25px;
    color: var(--White);
  }

  .header-6 {
    font-family: 'Montserrat-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
    letter-spacing: -0.3px;
    color: var(--Dark-Blue);
  }

  .header-6-variant{
    font-family: 'Montserrat-SemiBold';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.25px;
    color: var(--White);

    @media (max-width: 768px) {
      font-size: 22px;
      line-height: 30px;
    }
  }

  .h6-bold{
    font-family: 'Montserrat-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: -0.25px;
    color: var(--Dark-Blue);
  }

  .subtitle1 {
    font-family: 'Montserrat-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 23px;
    letter-spacing: -0.2px;
    color: var(--Dark-Blue);
    @media (max-width: 768px) {
      font-size: 19px;
      line-height: 27px;
    }
  }

  .subtitle1-variant{
    font-family: 'Montserrat-SemiBold';
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 24px;
    letter-spacing: -0.15px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: var(--White);
  }

  .subtitle2 {
    font-family: 'Montserrat-SemiBold';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.2px;
    color: var(--Black);
  }

  .subtitle2-variant{
    font-family: 'Montserrat-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.2px;
    color: var(--White);
  }

  .body-large{
    font-family: 'Montserrat-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 32px;
    letter-spacing: -0.2px;
    color: var(--Dark-Blue);
    @media (max-width: 768px) {
      font-family: 'Montserrat-Medium';
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
    }
  }

  .body-large-variant{
    font-family: 'Montserrat-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: -0.2px;
    color: var(--White);
  }

  .body-medium {
    font-family: 'Montserrat-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 29px;
    letter-spacing: -0.2px;
    color: var(--Dark-Blue);
  }

  .body-medium-variant{
    font-family: 'Montserrat-SemiBold';
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 22px;
    letter-spacing: -0.2px;
    color: var(--White);
  }


  .body-small{
    font-family: 'Montserrat-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    letter-spacing: -0.2px;
    color: var(--Dark-Blue);
    @media (max-width: 768px) {
      font-size: 17px;
      line-height: 25px;
    }
  }

  .body-small-variant{
    font-family: 'Montserrat-Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.2px;
    color: var(--White);
  }

  //All buttons will have this padding, to change the padding copy the class starting from ::part(native){...}
  ion-button::part(native){
    --padding-start: 20px;
    --padding-end: 20px;
    --padding-top: 20px;
    --padding-bottom: 20px;
  }

  .button-small {
      font-family: 'Montserrat-Medium';
      --ion-font-family: 'Montserrat-Medium';
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 22px;
      display: flex;
      align-items: center;
      letter-spacing: -0.2px;
      --color: var(--Dark-Blue);
      --border-radius: 40px;
      margin: 0;
  }

  .button-small-variant{
    font-family: 'Montserrat-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 22px;
    display: flex;
    align-items: center;
    letter-spacing: -0.15px;
    --border-radius: 40px;
    --color: var(--White);
  }



  .button-medium {
    font-family: 'Montserrat-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: -0.2px;
    color: var(--Dark-Blue);
  }

  .button-medium-variant {
    font-family: 'Montserrat-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 23px;
    display: flex;
    align-items: center;
    letter-spacing: -0.15px;
    color: var(--White);
  }

  .caption {
    font-family: 'Montserrat-Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 19px;
    letter-spacing: -0.2px;
    color: var(--Dark-Blue);
  }

  .caption-variant {
    font-family: 'Montserrat-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 19px;
    letter-spacing: -0.2px;
    color: var(--White);
  }

  .overline {
    font-family: 'Montserrat-Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    text-transform: uppercase;
    font-feature-settings: 'zero' on;
    color: var(--Dark-Blue);
  }

  .video-feature-tile {
    font-family: 'Montserrat-SemiBold';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 42px;
    display: flex;
    align-items: flex-end;
    letter-spacing: -0.2px;
    color: var(--Dark-Blue);
  }

  .page-title {
    font-family: 'Montserrat-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 64px;
    line-height: 74px;
    letter-spacing: -0.25px;
    color: var(--Dark-Blue);
  }

  .page-title-variant {
    font-family: 'Montserrat-SemiBold';
    font-style: normal;
    font-weight: 600;
    font-size: 64px;
    line-height: 74px;
    letter-spacing: -0.25px;
    color: var(--White);
  }
}