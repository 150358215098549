ion-modal.mindful-break-modal::part(content){
    max-width: 1200px;
    border-radius: 6px;
    width: 100%;

    @media (min-width: 550px) {
      width: 80vw;
    }
  }

ion-modal.mindful-break-modal {
  --height: fit-content;
  width: 100%;
}

.mindful-break-component{
  display: flex;
  font-size: 1.2rem;
  background-color: #fff;
  height: 100%;
  overflow: hidden;

  h2 {
    color: #4eded6;
    text-align: center;
  }

  .video {
    display: none;
  }

  h3 {
    color: black;
    font-size: 1.2rem;
  }

  .calendar-subscribe {
    flex: 1;
    padding: 1.5rem 1.5rem 2rem;
    margin: auto;

    p {
      color: #333;
      line-height: 1.5;
    }

    .calendar-providers {
      text-align: center;
    }
  }

  ul {
    list-style: none;
    li {
      line-height: 1.7;

      em {
        font-style: normal;
        font-weight: 600;
        display: inline-block;
        width: 3.5rem;
      }
    }
  }

  @media (min-width: 768px) {
    font-size: 1.1rem;
    align-items: stretch;
    height: 100%;

    .video, .calendar-subscribe {
      flex: 1;
      display: block;
    }

    .calendar-subscribe {
      max-width: 500px;
    }
  }
}
