.global-error-component {
  --backdrop-opacity: .1;
  .alert-wrapper {
    position: absolute;
    top: 15px;
    border-radius: 8px;
    --min-width: 200px;
    --max-width: 240px;
    --width: 250px;
    @media (min-width: 375px) {
      top: 35px;
      --min-width: 280px;
      --max-width: 280px;
      --width: 280px;
    }
    @media (min-width: 414px) {
      top: 35px;
      --min-width: 320px;
      --max-width: 320px;
      --width: 320px;
    }
    @media (min-width: 768px) {
      top: 90px;
      --min-width: 340px;
      --max-width: 340px;
      --width: 340px;
    }
    .alert-head{
      padding-top: 2px;
      @media (min-width: 768px) {
        padding-top: 5px;
        padding-bottom: 8px;
      }
      .alert-title{
        color: #eb445a;
        @media (min-width: 414px) {
          font-size: 20px;
        }
        @media (min-width: 768px) {
          font-size: 22px;
          text-align: center;
        }
      }
      .alert-sub-title{
        color: #eb445a;
        padding-top: 10px;
        @media (min-width: 414px) {
          font-size: 16px;
        }
        @media (min-width: 768px) {
          padding-top: 0;
          font-size: 17px;
          text-align: center;
        }
      }
    }
    .alert-message{
      color: #eb445a;
      font-size: 12px;
      @media (min-width: 414px) {
        font-size: 14px;
      }
      @media (min-width: 768px) {
        font-size: 15px;
        text-align: center;
      }
    }
  }
  &.error{
    --background: #eb445a;
  }
  &.success{
    .alert-head{
      .alert-title{
        color: #24aa59;
      }
    }
    .alert-message{
      color: #24aa59;
    }
  }
}
