.sso-login-page-component{
    .sso-login-page-content{
        .header {
            margin: 0 auto;
            padding-bottom: 20px;
            padding-top: 40px;
            height: auto;
            
        }

        .logo-image {
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 200px;
            height: auto;
        }

      .body{
        height: 100%;
        @media (min-width: 500px) {
          padding: 30px 0;
        }
        @media (max-width: 768px) {
          margin: 20vh auto;
        }
      }
    }
  }