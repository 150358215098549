.daily-practice-component{
    position: relative;
    .daily-practice-padding-container{
        padding: 2rem;
        .background-image-container{
            //negative positioning to counter box padding;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: -10;
            background: linear-gradient(90.04deg, #FFFFFF 8.07%, #77CAF1 99.96%);
            min-height: 22rem;
            //height: 280px;
            border-radius: 16px;
            .background-image{
                position: absolute;
                bottom: 0;
                right: 0;
                max-height: 220px;
                z-index: -9;

                @media (min-width: 768px) {
                    max-height: 280px;
                }
            }
        }
        .tile{
            .tile-text{
                color: var(--Dark-Blue);
                padding-top: 2.5rem;
                width: 45%;

            }
        }

        .daily-practice-video-tile {
            height: 100%;
        }
    }
    >.mux-video-player-component{
        margin: auto;
        width: 98%;
        height: 95%;
    }
}
