.tile-component{
    height: 100%;

    .title-and-description-container{
        padding: var(--extra-small) 0 0 0;
        .caption:first-of-type{
            color: var(--Dark-Blue);
        }
        .caption:nth-of-type(2){
            font-weight: 400;
            color: var(--Gray);

            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .tile-container {
        text-decoration: none;
        display: block;
        filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15));
        //box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
        border-radius: 16px;
        height: 100%;
    }
    .out-of-tile-title{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        display: inline-block;
    }
}

