.continuous-daily-journey-component{

  .daily-journey-component {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .newer-button-container{
    width: 100%;
    height: 3rem;
    background: linear-gradient(90deg, #7e82db 0%, #49c9d8 100%);

    .button{
      width: 100%;
      height: 100%;
      margin: 0;
      --border-radius: 0;
      --background: rgba(255,255,255,0.6);
      color: #333;
      --background-hover: rgba(255,255,255,0.8);
      --background-active: rgba(255,255,255,0.8);
      --background-disabled: rgba(255,255,255,0.8);

    }
  }

  .divider {
    height: 2px;
    background: rgb(126,129,213);
    background: linear-gradient(90deg, rgba(126,129,213,1) 0%, rgba(111,196,214,1) 100%);
  }
}
.older-button-container{
  display: flex;
  justify-content: center;
  width: 100%;
  height: 27px;
  margin: 0 0 20px 0;

  @media (min-width: 768px) {
    height: 45px;
  }
    .button{
      width: 100%;
      height: 100%;
      margin: 0;
      --border-radius: 0;
      --background: #EDEDED;
      color: #727272;
      --background-hover: #EDEDED;
      --background-active: #EDEDED;
      --background-disabled: #EDEDED;

  }
}