.schedule-carousel {
    position: relative;
    padding-top: var(--medium);
    padding-bottom: var(--unit);

    .arrow-button{
        position: absolute;
        width: 2rem;
        height: calc(100% - 26px - 10px);
        margin: 0;
        --padding-start: 0;
        --padding-end: 0;
        color: #000000;
        @media (min-width: 768px) {
            width: 4rem;
        }
        &.left{
            left: 0;
            transform: rotate(180deg);
        }
        &.right{
            right: 0;
        }
        .arrow-icon{
            width: 15px;
            height: 22px;
        }

        &.hide-arrow {
            visibility: hidden;
        }
        &::part(native){
            --padding-start: 0;
            --padding-end: 0;
            --padding-top: 0;
            --padding-bottom: 0;
        }
    }

    .schedule-swiper {
        white-space: nowrap;
        //100% - button width
        width: calc(100% - 4rem);
        margin: 0 2rem;
        @media (min-width: 768px) {
            margin: 0 4rem;
            width: calc(100% - 8rem);
        }

        .date-tile {
            width: 130px;
            text-align: center;
            >.radio-button-assessment-question-component{
                .radio-button-assessment-items-container{
                    .radio-button-assessment-checkbox-item{
                        .radio-button-assessment-checkbox{
                            width: 0;
                            height: 0;
                            &.radio-checked{
                                &::part(container){
                                    display: none;
                                }
                            }

                        }
                        .radio-button-assessment-label{
                            padding: 0
                        }
                    }
                }
            }
        }
    }
}