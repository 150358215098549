.exit-button-component{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  .exit-button {
    height: 18px;
    width: 18px;
    margin: 0;
    border-radius: 0;
    &::part(native){
      --padding-start: 0;
      --padding-end: 0;
      --padding-top: 0;
      --padding-bottom: 0;
    }
  }
}