ion-modal.download-mobile-app-modal::part(content){
    max-width: 479px;
    border-radius: 6px;
    width: 100%;

    @media (min-width: 550px) {
      width: 80vw;
    }
  }

ion-modal.download-mobile-app-modal {
  --height: fit-content;
  width: 100%;
}

.download-mobile-app-component{
  display: flex;
  font-size: 1.2rem;
  background-color: #fff;
  height: 100%;
  overflow: hidden;

  h2 {
    color: #000000;
    text-align: center;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
  }


  h3 {
    color: black;
    font-size: 1.2rem;
  }

  .download-mobile {
    flex: 1;
    padding: 1.5rem 1.5rem 2rem;
    margin: auto;

    .qr-code {
      padding-top: 20px;
      margin: auto;
      width: 144px;
      height: 184px;
    }
  }

  ul {
    list-style: none;
    li {
      line-height: 1.7;

      em {
        font-style: normal;
        font-weight: 600;
        display: inline-block;
        width: 3.5rem;
      }
    }
  }

  @media (min-width: 768px) {
    font-size: 1.1rem;
    align-items: stretch;
    height: 100%;

    .download-mobile {
      flex: 1;
      display: block;
      max-width: 500px;
    }
  }
}
