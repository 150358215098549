//box styling is in home page

.checkin-graph-component{
    .checkin-graph-container {
        height: 100%;

        &.prompt-visible {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .checkin-prompt-container{
            padding: 2.5rem 0 var(--unit) 0;

            @media (min-width: 768px) {
                padding: var(--small) 0 var(--unit) 0;
                //width: 100%;
            }
            .checkin-prompt{
                color: var(--Dark-Blue);
                text-align: center;
            }
            .checkin-buttons {
                display: grid;
                grid-template-columns: 25% 25% 25% 25%;
                justify-items: center;
                padding: 2rem 0 0 0;

                .checkin-button {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    background-color: white;
                    border: 0;
                    color: #333;
                    padding: 0;
                    max-width: var(--extra-large);
                    .checkin-image {
                        width: var(--extra-large);
                        height: var(--extra-large);
                        margin-bottom: var(--unit);
                    }
                }
            }
        }

        .graph {
            height: 100%;
            // min-height: 25vh;

            display: flex;
            padding: var(--unit) 0 0 0;
            flex-direction: column;

            @media (min-width: 768px) {
                min-height: unset;
                // padding: 0 1rem 0.5rem 0;
            }

            & > div {
                flex: 1;
            }

            .chart-label {
                background-color: white;
                padding: 0.5rem 1rem;
                border-radius: 3px;
                box-shadow: 2px 2px 2px rgba(100,100,100,0.5);
                display: flex;
                align-items: center;
            }

            .y-axis-label {
                cursor: pointer;

                &:hover > rect {
                    fill: #aaa;
                }
            }
        }
    }
}