.content-article-page{
  .article-content{
    .article-container{
      padding: 24px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      @media (min-width: 1440px) {
        padding: 41px;

      }
      .article-title{
        color: var(--Dark-Blue);
        padding: 10px 0 17px 0;
      }
      .article-body{
        color: var(--Dark-Blue);
        width: 100%;

      }
      .return-button-container{
        .return-button{
        }
      }
    }
  }
}