.blue{
    background: linear-gradient(90.04deg, #FFFFFF 8.07%, #77CAF1 99.96%);
    border-radius: 16px;
}

.purple{
    background: linear-gradient(90deg, #FFFFFF 0%, #D8BBFF 100%);
    border-radius: 16px;
}

.yellow{
    background: linear-gradient(90deg, #FFFFFF 0%, #FFCD70 100%);
    border-radius: 16px;
}

.green{
    background: linear-gradient(90.04deg, #FFFFFF 15.73%, #94EBE5 99.96%);
    border-radius: 16px;
}

.pink{
    background: linear-gradient(90deg, #FFFFFF 0%, #F5C0C7 100%);
    border-radius: 16px;
}

.video-style-component{
    border-radius: 16px;
    height: 100%;
    .image-container {
        position: relative;
        height: 100%;
        background-size: cover;
        border-radius: 16px;
        aspect-ratio: var(--aspect-ratio);

        .play-icon {
            width: 4rem;
            height: 4rem;
            position: absolute;
            top: calc(50% - 2rem);
            left: calc(50% - 2rem);
            stroke: white;
            display: none;
            z-index: 2;
        }

        &:hover {
            .play-icon {
                display: block;
            }
        }
        .title-container{
            width: 50%;
            display: inline-block;
            z-index: 2;
            position: inherit;
            .title{
                padding: var(--extra-small) 0 0 var(--extra-small);
                white-space: normal;
                width: 100%;
                display: inline-block;
                overflow-wrap: break-word;
                word-wrap: break-word;
                color: var(--Dark-Blue);
            }
            .author-container{
                padding: 1rem 0 0 var(--extra-small);
                .author-name{
                    text-transform: uppercase;
                }
            }
        }
    }
}

