.resources-back-button-component{
  display: flex;
  align-items: center;
  cursor: pointer;

  .resources-back-icon {
    font-size: 2rem;
  }

  .resources-back-button {
    &::part(native){
      --padding-start: 0 !important;
      --padding-end: 0 !important;
      --padding-top: 0 !important;
      --padding-bottom: 0 !important;
    }
    @media (min-width: 768px) {
      margin: 0;
      border-radius: 0;
      height: 20px;
      width: 64px;
    }

    span {
      color: #000000;
      padding: 0 0 0 10px;
      margin: 0;
      text-transform: capitalize;
    }
  }
}