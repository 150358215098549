.assessment-question-component{
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .assessment-question-copy{
    text-align: center;
    margin: 0 auto;
    padding: var(--large) 0 2rem 0;
    max-width: 255px;
    @media (min-width: 375px) {
      max-width: 352px;
    }
    @media (min-width: 768px) {
      padding: var(--medium) 0 2rem 0;
    }
  }
  .assessment-buttons-container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media (min-width: 768px) {
      flex-direction: row;
      padding: 2rem 0 0 0;
    }
    &.vertical{
      flex-direction: column;
    }

    .assessment-button{
      background-color: #fff;
      --background: #fff;
      --background-activated: #fff;
      --background-focused: #fff;
      --background-hover: #fff;
      color: var(--Gray);
      border: 1px solid var(--Gray);
      border-radius: 40px;
      width: 280px;
      height: var(--large);
      margin: 7px 0;
      &::part(native){
        --border-radius: 40px;
      }
      &.vertical{
        margin: 6px 0;
      }
      &.hover{
        color: var(--Green);
        border: 1.5px solid var(--Green);
        --border-color:  1.5px solid var(--Green);
      }
      &.wide{
        width: calc(100% - 90px);
        @media (min-width: 768px) {
          width: calc(100% - 210px);
        }
      }
      &:first-of-type{
        margin: 10px 0 6px 0;
        @media (min-width: 768px) {
          margin: 0 6px;
          &.vertical{
            margin: 6px 0;
          }
        }
      }
      &:last-of-type{
        margin: 6px 0 10px 0;
        @media (min-width: 768px) {
          margin: 0 6px;
          &.vertical{
            margin: 6px 0 var(--large) 0;
          }
        }
      }
    }
  }

  .assessment-bound-copy-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .assessment-bound-copy{
    padding-bottom: 2rem;
  }

  .assessment-input-container{
    display: flex;
    align-items: center;
    justify-content: center;
    .input-item{
      width: 70px;
      --inner-border-width: 0 0 1px 0;
      --padding-start: 0;
      .number-input{
        width: 50px;
        --padding-start: 25px;
        --padding-end: 0px;
        --padding-bottom: 0;
      }
    }

    .input-label{
      display: flex;
      align-self: flex-end;
      margin: 0 0 0 0;
    }
  }
  .assessment-items-container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 2rem;
    .assessment-checkbox-item{
      border: 1px solid var(--Gray);
      border-radius: 40px;
      width: 280px;
      height: var(--large);
      margin: 7px 0;
      --padding-start: 16px;
      --inner-border-width: 0px;
      &::part(native){
        --border-radius: 40px;
        height: var(--large);
        --background-hover: var(--White);
        --background-hover-opacity: 0;
      }
      &.selected{
        transform: scale(1.02);
        transition: ease;
        transition-duration: .3s;
        color: var(--Green);
        border: 1.5px solid var(--Green);
        --border-color: 1.5px solid var(--Green);
      }
      &.more-bottom-padding{
        margin: 6px 0 20px 0;
        @media (min-width: 768px) {
          margin: 6px 0 27px 0;
        }
      }
      .assessment-checkbox{
        display: flex;
        align-items: center;
        margin: 0;
        &.selected{
          &::part(container){
            border-radius: 50%;
            width: 25px;
            height: 25px;
            display: block;
          }
        }
        &::part(container){
          display: none;
        }
        &::part(mark){
          --checkmark-width: 3;
        }
      }
      .assessment-label{
        padding: 0 32px 0 0;
        display: block;
        text-align: center;
        color: var(--Gray);

        &.selected {
          color: var(--Green);
        }
      }
    }
    .assessment-checkbox-item:hover{
      border: 1px solid var(--Green);

      .assessment-label{
        color: var(--Green);
      }
    }
  }

}