.category-header-component {
    display: block;
    align-items: center;

    @media (min-width: 768px) {
        display: flex;
    }

    a {
        text-decoration: none;
    }
    .title-description-container {
        display: inline;
        .description-text{
            color: var(--Gray);
            @media (min-width: 768px) {
                margin-left: var(--large);
            }
        }
    }

    .title-text{
        color: var(--Gray);
        @media (min-width: 768px) {
            margin-left: var(--large);
        }
    }

    ion-icon {
        font-size: 1.4rem;

        @media (min-width: 768px) {
            display: none;
        }
    }
}