.resources-carrousel-swiper{
  position: relative;
  height: 30vh;

  @media (min-width: 768px){
    height: 35vh;
  }

  @media (min-width: 1200px){
    height: 40vh;
  }

  @media (min-width: 1400px){
    height: 45vh;
  }

  .dots {
    position: absolute;
    left: 2rem;
    bottom: 10%;
    z-index: 10;
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;

    @media (min-width: 768px){
      left: 60px;
      bottom: 20%;
    }

    li {
      height: 1.2rem;
      width: 1.2rem;
      background-color: rgba(255,255,255,0.3);
      border-radius: 50%;
      margin-right: 1.2rem;
      cursor: pointer;

      &.selected {
        background-color: rgba(255,255,255, 1);
      }
    }
  }

  .resources-swiper-pagination{
    .swiper-pagination-bullet {
      background: #ffffff;
      opacity: .4;
    }
    .swiper-pagination-bullet-active{
      opacity: 1;
    }

  }
  .resources-swiper-slide{
    .resources-slide-background{
      position: relative;
      display: flex;
      background-position: 0 15%;
      background-size: cover;
      height: 100%;

      @media (min-width: 768px){
        background-color: #000000;
        height: 100%;
      }
      .resources-text{
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: #fff;
        padding: 0 0 0 2rem;
        @media (min-width: 768px){
          padding: 0 0 0 60px;
        }

        .resources-title{
          color: var(--White);
          @media (min-width: 768px){
          font-size: 40px;
          line-height: 48px;

        }
        }
        .resources-description {
          color: var(--White);
          width: 70%;
          @media (min-width: 768px){
            width: 63%;
          }
          @media (min-width: 1440px){
            width: 40%;

          }
        }
      }
      .resources-thumbnail-container{
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: rgba(0,0,0,0.4);
      }
    }
  }
}