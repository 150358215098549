
.world-record-page-component{
    .world-record-content{
      .world-record-video-container {
        height: calc(100vw / 1.7777777);
        max-height: 60vh;
        min-width: 100%;
      }
      .world-record-content-container{
        padding: 3rem;
        display: flex;
        justify-content: center;

        .text-content-container {
          display: flex;
          flex-direction: column;
          text-align: center;
          max-width: 850px;
        }

        .logo-image{
          width: 350px;
          align-self: center;
          max-width: 75%;
        }
        .title{
          margin-bottom: 2rem;
          font-size: 2.2rem;
        }
        .subtitle{
          margin-bottom: 1rem;
          font-size: 1.8rem;
        }
        .button{
          width: 180px;
        }
        .socials{
          display: flex;
          justify-content: center;
          margin-bottom: 2rem;
          flex-wrap: wrap;

          .button {
            margin: 1rem;

          }

          .facebook-button{
            --background: #3D5A96;
          }
          .linkedin-button{
            --background: #007AB7;
          }
          .twitter-button{
            --background: #2AA3EF;
          }
          .instagram-button{
            --background: linear-gradient(180.19deg, #7024C4 0.17%, #C21975 41.57%, #C74C4D 70.15%, #E09B3D 99.83%);
          }
          .share-button{

          }
        }
        .signup{
          margin-top: 0;
          margin-bottom: 2rem;
          font-size: 1.8rem;
        }
        .error-text {
          text-align: center;
        }
        .email-input {
          margin-bottom: 2rem;
          border: solid 1px #92949C;
          border-radius: 10px;
          --inner-border-width: 0;
          min-width: 250px;
          max-width: 600px;
          width: 50vw;
          align-self: center;
          &.error {
            border: solid 1px red;
          }
        }

        .submit-button{
          align-self: center;
          font-size: 1.3rem;
        }
      }
    }
  }
