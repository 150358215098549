.daily-journey-page {
    background: white;

    > .daily-journey-component {
        margin: 0 auto;
        width: 100%;
        padding-bottom: 50px;
    }

    .dj-header {
        display: inline-flex;
        justify-content: space-between;
        width: 100%;
        height: 200px;
        background: linear-gradient(90.04deg, #FFFFFF 8.07%, #77CAF1 99.96%);
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 85%);
        @media (min-width: 768px) {
            height: 350px;
        }
        .daily-journey-text{
            display: flex;
            align-items: flex-end;
            font-size: 35px;
            line-height: 40px;
            padding: 0 0 var(--extra-large) 2rem;
            max-width: 155px;
            @media (min-width: 768px) {
                word-spacing: normal;
                font-size: 64px;
                line-height: 74px;
                padding: 0 0 120px var(--extra-large);
                max-width: 500px;
            }
        }

        .image{
            position: absolute;
            right: 0;
            z-index: -1;
            width: auto;
            aspect-ratio: 583/351;
            height: 200px;
            //Following the height of dj-header
            @media (min-width: 768px) {
                height: 350px;
                right: var(--medium);
            }

        }
    }
}
