.home-page {
    background: var(--Beige);

    .main-content-container {
        --ion-background-color: transparent;
    }

    .video-listing-row:first-of-type {
        padding: 3rem 1rem 0;
        @media (min-width: 768px) {
            padding: var(--medium) 0 0 0;
        }
    }
    .home-page-line-divider-container{
        padding: 0 2rem;
        height: 2px;
        background: white;
        @media (min-width: 768px) {
            padding: 0 var(--extra-large);
        }
        .home-page-line-divider{
            height: 1px;
            min-height: 1px;
            background: linear-gradient(90deg, #7E82DB 0%, #49C9D8 100%);
            filter: drop-shadow(0 0 0 var(--White));
        }
        &.first{
            padding: 0 2rem;
            @media (min-width: 768px) {
                padding: var(--small) var(--extra-large) 0 var(--extra-large);
            }
        }
    }
    .video-listing-row-container{
        .video-listing-row {
            width: 100%;
            margin: 0;
            padding: var(--medium) 2rem;
            background-color: white;

            @media (min-width: 768px) {
                padding: var(--medium) var(--unit);
            }

        }
    }

    .home-page-daily-checkin {
        padding: 2rem var(--extra-small);

        @media (min-width: 768px) {
            padding: var(--extra-small);
        }
        @media (min-width: 1024px) {
            padding: var(--medium);
        }

        .user-greeting-text {
            color: var(--Gray);
            margin: 0;
            padding-top: 2rem;
            padding-bottom: 2.5rem;
            text-align: center;

            @media (min-width: 768px) {
                padding-top: 0;
                padding-bottom: 0;
            }

            @media (min-width: 1024px) {
                padding-left: var(--extra-small);
                text-align: start;
                display: block;
            }
        }

        .checkin-steps-container {
            @media (min-width: 1024px) {
                display: grid;
                margin-top: 2rem;
                grid-template-columns: 1fr 1fr 1fr;
            }

            .box {
                background-color: white;
                border-radius: 16px;
                display: flex;
                flex-direction: column;
                min-height: 22rem;
                height: 220px;
                margin-bottom: 1.5rem;
                filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15));
                padding: 2rem;

                @media (min-width: 768px) {
                    max-width: 500px;
                    margin: 2rem auto;
                    height: 280px;
                }
                @media (min-width: 1024px) {
                    padding: var(--small);
                    margin: 0 var(--unit) 0 0;

                }

                &:last-child {
                    margin-bottom: 0;
                    height: 290px;
                    padding: 2rem 2rem 0;
                    @media (min-width: 1024px) {
                        margin-right: 0;
                        height: 280px;
                    }
                }

                &:nth-of-type(2){
                    padding: 0;
                    background-color: var(--Black);
                }

                .box-header {
                    display: flex;
                    align-items: center;
                    padding: 0 0 var(--unit) 0;
                    border-bottom: .75px solid var(--Gray);

                    .box-number {
                        width: 1.8rem;
                        height: 1.8rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: 1px solid var(--Green);
                        border-radius: 50%;
                        margin-right: 1rem;

                        &.complete {
                            background-color: var(--Green);
                            color: white;
                            border: none;
                        }
                    }

                    .box-title {
                        flex: 1;
                        display: block;
                        @media (min-width: 1440px) {
                            display: flex;
                            justify-content: space-between;
                        }
                        .title-container{
                            display: block;
                            @media (min-width: 1024px) {
                                display: inline-flex;
                            }
                        }
                        .title{
                            margin-right: var(--unit);
                            .date{
                                display: inline-flex;
                            }
                        }
                    }

                    .box-suffix {
                        img {
                            vertical-align: middle;
                        }
                    }
                }

                .tile {
                    flex: 1;
                    overflow: hidden;
                }
            }
        }
    }


    .resources {

        .benefits {
            flex: 1;
            display: flex;
            white-space: nowrap;
            padding: var(--unit);
            overflow-x: auto;
            .benefits-inner {
                display: flex;
                overflow-x: auto;
                overflow-y:hidden;
            }


            @media (min-width: 768px) {
                padding: 0 var(--large) var(--unit) var(--large);
                display: flex;
                margin: 0;

                .benefits-inner {

                    margin-left: auto;
                    margin-right: auto;

                }
            }
            &::-webkit-scrollbar {
                height: var(--unit);
                border-radius: 20px;
            }

            &::-webkit-scrollbar-track {
                background: #fff;
            }

            &::-webkit-scrollbar-track:hover {
                background: #fff;
            }

            &::-webkit-scrollbar-thumb {
                background: var(--Gray);
                border-radius: 20px;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: var(--Gray);
            }
        }

        .logo {
            margin: 0 2rem;
            padding: 2rem 0;
            cursor: pointer;
            display: flex;
            justify-content: center;
            flex-shrink: 0;
            align-items: center;
            max-width: 25vw;

            img {
                max-height: 3rem;
                filter: grayscale(1);
            }
        }
    }

    .empty-my-list {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: 0 4vw;

        h3, h3 a {
            color: #02e2d7;
        }

        p {
            margin: 0 1rem;
            font-size: 1.2rem;
            color: #333;
            text-align: center;
        }

        hr {
            flex: 1;
            background-color: #333895;
            max-width: 12vw;
            margin: 0;
        }

        .add-to-playlist {
            vertical-align: middle;
            width: 28px;
            height: 28px;
            shape-rendering: crispEdges;

            @media (min-width: 768px) {
                width: 20px;
                height: 20px;
                padding-left: 4px;
            }

            @media (min-width: 1200px) {
                width: 32px;
                height: 32px;
                padding-left: 4px;
            }

        }
    }
}