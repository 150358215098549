.profile-header {
    display: flex;
    cursor: pointer;
    align-items: center;
    padding: 0 var(--extra-small);

    .name-container {
        display: flex;
        flex-direction: column;
        white-space: nowrap;
        .name {

        }
    }


    .down {
        transform: rotate(180deg);
    }

    .up {
        transform: rotate(0deg);
    }


    .progress-bar-container {
        width: 40px;
        height: 40px;
        margin: 0 var(--extra-small);
    }

    .dropdown-button{
        margin: 0 7px;
        &::part(native){
            --padding-start: 0;
            --padding-end: 0;
            --padding-top: 0;
            --padding-bottom: 0;
        }
        &.selected {
          font-weight: bold;
          border-bottom: 1px solid black;
        }

        .dropdown-icon {
            width: 12px;
        }
    }
}