.clinician-appointment-component{
    .clinician-container{
        display: flex;
        align-items: center;
        padding: 0 2rem;
        @media (min-width: 768px) {
            padding: 0 var(--medium);
        }
        .clinician-image-container{
            display: contents;
            .clinician-image{
                border-radius: 50%;
                width: 105px;
                height: 105px;
            }
        }

        .clinician-name-title-degree-container{
            padding-left: var(--extra-small);
            .clinician-name{
                color: var(--Dark-Blue);
                display: flex;
                .clinician-pronouns{
                    padding-left: 5px;
                }
            }
        }
    }

    .clinician-bio-container{
        padding: 0 2rem;
        @media (min-width: 768px) {
            padding: 0 var(--medium);
        }
        .clinician-bio{
            margin-top: var(--unit);

        }
    }
    .calendar-container{
        width: 100%;
    }
}