.share-component{
  display: flex;
  flex-direction: row;
  @media (max-width: 900px) {
    flex-direction: column;
  }
  justify-content: space-between;
  .text-container{
    margin-right: 20px;
    padding: var(--unit) 0;
  }
  .button-container{
      margin-top: auto;
      margin-bottom: auto;

    .share-button{
      width: 108px;
      padding: 0;
    }
    .share-button:hover{
      color: var(--Green);
      .share-button-icon{
        filter: invert(0%) sepia(3%) saturate(7487%) hue-rotate(288deg) brightness(83%) contrast(98%);
      }
      &::part(native) {
        border: solid 2px var(--Green);
      }
    }
  }
}