.mux-live-video-player-component {

    &.hide-controls{
        mux-player::part(vertical-layer){
            display: none;
        }
        mux-player::part(centered-layer){
            display: none;
        }
    }

    mux-player {
        aspect-ratio: 640 / 360;
        max-height: 60vh;
        min-width: 100vw;
    }
    .live-video-player-container {
        aspect-ratio: 640 / 360;
        max-height: 60vh;
        min-width: 100vw;

        .video-loading-container{
            background: #0d0d0d;
            width: 100%;
            height: 100%;
            .video-loading-container-text{
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                margin: 0;
                color: #fff;
                font-size: 1.3rem;
                font-weight: 400;
                padding: 5px;
                @media (min-width: 375px) {
                    font-size: 1.5rem;
                    padding: 2px;
                }
                @media (min-width: 768px) {
                    font-size: 2rem;
                    font-weight: 500;
                    padding: 0;
                }
                .loading-spinner{
                    margin: 5px;
                    --color: rgb(255, 255, 255);
                }
            }
        }
    }
    .text-container{
        padding: 3vw 8vw;

        .title{
            font-weight: 500;
            font-size: 1.6rem;
            margin: 0;

            @media (min-width: 768px) {
                font-size: 2rem;
            }
        }
        .comments{
            font-size: 1.4rem;
            line-height: 1.5;
            margin: 2rem 0 1rem;
            font-weight: 700;
            color: #000000;
            text-transform: uppercase;
            text-align: center;
        }
        .line-divider{
            height: 2px;
            min-height: 2px;
            background: #4EDED6;
            margin: 0;
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
        }
    }
}
