
.video-page-component{
    .video-content{
        .video-page-wrapper{
            max-width: 1000px;
            margin: 0 auto;
            .video-container {
                //height: calc(100vw / 1.7777777);
                //max-height: 60vh;
                min-width: 100%;
                @media (min-width: 768px) {
                    //padding-top: 100px;
                }
            }
            .video-content-container{
                padding: var(--large) 2rem;

                @media (min-width: 768px) {
                    padding: 7rem 0;
                }

                .title{
                    color: var(--Dark-Blue);
                }
                .duration{
                    margin: 1rem 0 2rem 0;
                    color: var(--Gray);
                }
                .description{
                    margin: 0 0 7rem 0;
                }
                .line-divider{
                    height: 2px;
                    min-height: 2px;
                    background: linear-gradient(90deg, #7E82DB 0%, #49C9D8 100%);
                    margin: 0;
                    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
                }
                .comments{
                    padding: var(--large) 0 2rem 0;

                    @media (min-width: 768px) {
                        padding: var(--large) 0 var(--small) 0;
                    }
                }
            }
        }
    }
}
